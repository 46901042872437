import { React, useState } from "react";
import { Button, CardContent, TextField, Card, Typography, Grid } from "@material-ui/core";
import { updateCategory, getCategoryById } from "../../../api/categoryApi/categoryApi";
import { Link } from 'react-router-dom';
import { useEffect } from "react";


const EditCategoryModal = (props) => {

    const { history } = props;
    const { categoryId } = props.match.params;
    const [categoryName, setCategoryName] = useState("");
    const [isDisabled, setIsDisabled] = useState(false);

    const updatedCategory = {
        id: categoryId,
        categoryName: categoryName
    }

    const handleCategoryById = async () => {
        await getCategoryById(categoryId)
            .then((res) => {
                setCategoryName(res.data.categoryName);
            })
    }

    const updateCategoryInternal = async (event) => {
        event.preventDefault();
        await updateCategory(updatedCategory);
        history.push("/categories");
    }

    useEffect(() => {
        handleCategoryById();
    }, []);

    const handleChange = (event) => {
        setCategoryName(event.target.value);
    };

    const handleClick = () => {
        setIsDisabled(true);
    };

    return (
        <div>
            <Card style={{ maxWidth: 600, margin: '0 auto', padding: '20px 20px' }}>
                <CardContent>
                    <Typography gutterBottom variant="h3" style={{ textAlign: 'center' }}>
                        Update Category
                    </Typography>
                    <Grid container>
                        <Grid xs={12} item style={{ paddingTop: '20px', paddingRight: '20px' }}>
                            <form style={{ paddingLeft: "80px" }}>
                                <TextField
                                    type="text"
                                    fullWidth
                                    label="Category name"
                                    variant="standard"
                                    value={categoryName}
                                    onChange={handleChange}
                                />
                            </form>
                        </Grid>
                        <Grid xs={12} sm={6} item style={{ paddingTop: "20px" }}>
                            <Button
                                variant="text"
                                size="large"
                                color="primary"
                                varient="outlined"
                                fullWidth
                                component={Link}
                                to="/categories"
                            >
                                <span className="category-form-btn-color">&lt;Go Back</span>
                            </Button>
                        </Grid>
                        <Grid xs={12} sm={6} item style={{ paddingTop: "20px" }}>
                            <Button
                                type="submit"
                                size="large"
                                varient="outlined"
                                color="primary"
                                fullWidth
                                onClick={(e) => {
                                    handleClick();
                                    updateCategoryInternal(e);
                                }}

                            >
                                <span className="category-form-btn-color">Update</span>
                            </Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </div >
    )

};

export default EditCategoryModal;
