export const processProductCategory = (categoryId) => {
  if (categoryId === 1) {
    return "books";
  } else {
    return "videos";
  }
};

export const extractToken = () => {
  const token = sessionStorage.getItem("token");
  if (token) {
    return token;
  }
};

export const dateFormat = (product) => {
  return new Date(product.borrowDate).toISOString().split("T")[0];
};

export const noReturnDate = (product) => {
  return product.returnDate.toString().substring(0, 3).includes("000");
};

export const findUserById = (product, users) => {
  const userName = users.find((user) => user.id === product.userId) || "-";
  return userName.displayName;
};

export const proccessFieldType = (typeNumber) => {
  switch (typeNumber) {
    case 1:
      return "text";
    case 2:
      return "number";
    case 3:
      return "decimal";
    case 4:
      return "date";
    case 5:
      // be datetime
      return "tags";
    case 6:
      return "time";
    case 7:
      return "boolean";
    case 8:
      return "textarea";
    case 9:
      return "file";
    default:
      break;
  }
};
