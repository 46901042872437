import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../authConfig";
import { Button, Box, Paper, Dialog } from "@material-ui/core/";
import { useContext } from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";
import { OrdersFormProvider } from "../../../context/OrdersProvider";



const DeleteOrderItemModal = ({ openPopup, handleClose, orderId, orderItemId, orderItems, index, setOrderItems }) => {

    const { instance } = useMsal();
    const { handleDeleteOrderItemById } = useContext(OrdersFormProvider);

    const deleteOrderItem = () => {
        let filteredOrderItemList = orderItems.splice(orderItemId, index);
        setOrderItems(filteredOrderItemList);
    }

    return (
        <Dialog
            open={openPopup}
            onClose={handleClose}
            BackdropProps={{ style: { backgroundColor: "transparent", boxShadow: 'none', } }}
        >
            <Paper>
                <div className="modal">
                    <div className="modal__header">
                        <h2>Are you sure?</h2>
                    </div>
                    <div className="modal__btn__group">
                        <div className="modal__btn__1">
                            <Box ml={1}>
                                <Button
                                    size="small"
                                    color="primary"
                                    startIcon={<CloseIcon fontSize="small" className="category-form-btn-color" />}
                                    onClick={handleClose}
                                >
                                    <span className="category-form-btn-color">Cancel</span>
                                </Button>
                            </Box>
                        </div>
                        <div className="modal__btn__2">
                            <Box ml={1}>
                                <Button
                                    color="secondary"
                                    size="small"
                                    className="button__delete"
                                    onClick={() => {
                                        handleDeleteOrderItemById(orderId, orderItemId);
                                        handleClose();
                                        deleteOrderItem();
                                    }}
                                    startIcon={<DeleteIcon fontSize="small" />}
                                >
                                    Delete
                                </Button>
                            </Box>
                        </div>
                    </div>
                </div>
            </Paper>
        </Dialog>
    );
};


export default DeleteOrderItemModal;
