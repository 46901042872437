import React, { useContext, useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import { FormProvider } from "../../context/FormProvider.js";
import { AuthContext } from "../../context/AuthProvider";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Button,
  Chip,
} from "@material-ui/core";
import DetailsModal from "../modal/DetailsModal/DetailsModal";
import DeleteModalDetails from "../modal/DeleteModalDetails/DeleteModalDetails.jsx";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    textAlign: "center",
    justifyContent: "space-around",
    color: theme.palette.text.secondary,
  },
}));

const ProductsTable = ({
  productsData,
  columns,
  loadingIndicator,
  paginationChange,
  setLoadingIndicator
}) => {
  const classes = useStyles();
  const { userRole } = useContext(AuthContext);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const { productTypes, getProductTypes } = useContext(FormProvider);

  useEffect(() => {
    getProductTypes();
  }, []);

  // if (productsData.length === 0) {
  //   setLoadingIndicator(false);
  // }

  // if (productsData.length > 0) {
  //   setLoadingIndicator(true);
  // }

  if (!productsData.length && loadingIndicator) {
    return (
      <div className="loading_div">
        <div className="lds-dual-ring"></div>
      </div>
    );
  }

  if (!loadingIndicator) {
    return (
      <div className="loading_div" style={{ paddingTop: '50px' }}>
        <h1>No Results Found!</h1>
      </div>
    );
  }

  return (
    <>
      <DetailsModal open={isOpenDelete} onClose={() => setIsOpenDelete(false)}>
        <DeleteModalDetails
          deleteId={deleteId}
          paginationChange={paginationChange}
          setIsOpenDelete={setIsOpenDelete}
          onClose={() => setIsOpenDelete(false)}
        />
      </DetailsModal>
      <TableContainer className={classes.container}>
        <Table aria-label="a dense table" className="product-table-container">
          <TableHead>
            <TableRow className="table__head">
              {columns.map((column, i) => (
                <TableCell
                  key={column.id}
                  className="table__head__cell"
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {productsData &&
              productsData.map((product) => {
                return (
                  <TableRow hover tabIndex={-1} key={product.id}>

                    {product.fieldValues && product.fieldValues.length
                      ? product.fieldValues.map((fieldValue, index) => {
                        if (fieldValue.fieldDefinitionName === "Name") {
                          return (
                            <Fragment key={index}>
                              <TableCell>
                                {fieldValue.fieldValue}
                              </TableCell>
                            </Fragment>
                          )
                        }
                      })
                      : "/"}


                    <TableCell className="capitalize" align="left">
                      {product.productTypeName}
                    </TableCell>
                    <TableCell align="left">
                      {product.tags &&
                        product.tags.map((el) => {
                          return (
                            <Chip
                              key={el.id}
                              className="chiptable"
                              label={el.name}
                              size="small"
                              color="primary"
                            />
                          );
                        })}
                    </TableCell>

                    <TableCell align="right">
                      <Box display="flex" justifyContent="flex-end">
                        <Box ml={1}>
                          {userRole === "Admin" ? (
                            <Button
                              className="button__edit"
                              startIcon={<EditOutlinedIcon className="edit-icon" />}
                              component={Link}
                              to={`/products/edit/${product.id}`}
                            >
                            </Button>
                          ) : null}
                        </Box>
                        <Box ml={1}>
                          {userRole === "Admin" && (
                            <Button
                              onClick={() => {
                                setIsOpenDelete(true);
                                console.log(product)
                                setDeleteId({
                                  id: product.id,
                                  category: product.category,
                                });
                              }}
                              className="button__delete"
                              startIcon={<DeleteOutlineIcon className="delete-icon" />}
                            >
                            </Button>
                          )}
                        </Box>
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
export default ProductsTable;
