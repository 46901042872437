import {
    Button,
    Typography,
    TextField,
    Select,
    Grid,
    InputLabel,
    Card,
    CardContent,
    MenuItem
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import Moment from 'moment';
import { getSingleOrder, updateOrder } from "../../api/ordersApi/ordersApi";



const EditOrderForm = (props) => {

    const { orderId } = props.match.params;
    const { history } = props;
    const [orderForUpdate, setOrderForUpdate] = useState({});
    const [orderStatus, setOrderStatus] = useState(0);
    const [isDisabled, setIsDisabled] = useState(false);
    const { supplier, deliveryAddress, status, createdOrderDate, deliveryOrderDate, note } = orderForUpdate;

    const { instance } = useMsal();

    const fetchOrderForUpdating = async () => {
        await getSingleOrder(orderId).then((res) => {
            setOrderForUpdate(res.data);
            setOrderStatus(res.data.status);
        })
    }
    
    useEffect(() => {
        fetchOrderForUpdating();
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        await updateOrder(orderForUpdate);
        history.push(`/orders`);
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setOrderForUpdate({ ...orderForUpdate, [name]: value });
        if (name === "status") {
            setOrderStatus(value);
        }
    };

    const handleClick = () => {
        setIsDisabled(true);
        console.log("asd")
      };
    

    return (
        <div>
            <Card style={{ maxWidth: 600, margin: '0 auto', padding: '20px 20px' }}>
                <CardContent>
                    <Typography gutterBottom variant="h2" style={{ textAlign: 'center' }}>
                        Update Order
                    </Typography>
                    <form>
                        <Grid container>

                            <Grid xs={12} item style={{ paddingTop: '20px' }}>
                                <InputLabel htmlFor="bootstrap-input" style={{ paddingBottom: '5px' }}>
                                    Delivery Address
                                </InputLabel>
                                {status !== 2 ?
                                    <TextField
                                        variant="standard"
                                        type="text"
                                        name="deliveryAddress"
                                        fullWidth
                                        value={deliveryAddress}
                                        onChange={handleChange}
                                    /> :
                                    <TextField
                                        variant="standard"
                                        type="text"
                                        name="deliveryAddress"
                                        fullWidth
                                        disabled
                                        value={deliveryAddress}
                                        onChange={handleChange}
                                    />}
                            </Grid>

                            <Grid xs={12} sm={6} item style={{ paddingTop: '20px', paddingRight: "20px" }}>
                                <InputLabel htmlFor="bootstrap-input" style={{ paddingBottom: '5px' }}>
                                    Created Order Date
                                </InputLabel>
                                {status !== 2 ?
                                    <TextField
                                        variant="standard"
                                        type="date"
                                        fullWidth
                                        name="createdOrderDate"
                                        value={Moment(createdOrderDate).format('YYYY-MM-DD')}
                                        onChange={handleChange}
                                    />
                                    :
                                    <TextField
                                        variant="standard"
                                        type="date"
                                        fullWidth
                                        disabled
                                        name="createdOrderDate"
                                        value={Moment(createdOrderDate).format('YYYY-MM-DD')}
                                        onChange={handleChange}
                                    />}
                            </Grid>

                            <Grid xs={12} sm={6} item style={{ paddingTop: '20px' }}>
                                <InputLabel htmlFor="bootstrap-input" style={{ paddingBottom: '5px' }}>
                                    Delivery Order Date
                                </InputLabel>
                                {status !== 2 ?
                                    <TextField
                                        variant="standard"
                                        type="date"
                                        fullWidth
                                        name="deliveryOrderDate"
                                        defaultValue={Moment(deliveryOrderDate).format('YYYY-MM-DD')}
                                        onChange={handleChange}
                                    />
                                    :
                                    <TextField
                                        variant="standard"
                                        type="date"
                                        fullWidth
                                        disabled
                                        name="deliveryOrderDate"
                                        defaultValue={Moment(deliveryOrderDate).format('YYYY-MM-DD')}
                                        onChange={handleChange}
                                    />}
                            </Grid>

                            <Grid xs={12} sm={6} item style={{ paddingTop: '20px', paddingRight: "20px" }}>
                                <InputLabel htmlFor="bootstrap-input" style={{ paddingBottom: '5px' }}>
                                    Suplier
                                </InputLabel>
                                {status !== 2 ?
                                    <TextField
                                        variant="standard"
                                        type="text"
                                        fullWidth
                                        name="supplier"
                                        value={supplier}
                                        onChange={handleChange}
                                    />
                                    :
                                    <TextField
                                        variant="standard"
                                        type="text"
                                        fullWidth
                                        name="supplier"
                                        disabled
                                        value={supplier}
                                        onChange={handleChange}
                                    />}
                            </Grid>


                            <Grid xs={12} sm={6} item style={{ paddingTop: '20px' }}>
                                <InputLabel htmlFor="bootstrap-input" style={{ paddingBottom: '5px' }}>
                                    Order Status
                                </InputLabel>
                                <Select
                                    value={orderStatus}
                                    fullWidth
                                    variant="standard"
                                    name="status"
                                    onChange={handleChange}
                                >
                                    <MenuItem value={1}>InProgress</MenuItem>
                                    <MenuItem value={2}>Completed</MenuItem>
                                </Select>
                            </Grid>

                            <Grid xs={12} item style={{ paddingTop: '20px' }}>
                                <InputLabel htmlFor="bootstrap-input" style={{ paddingBottom: '5px' }}>
                                    Note
                                </InputLabel>
                                {status !== 2 ? 
                                <TextField
                                    variant="standard"
                                    type="text"
                                    multiline rows={4}
                                    fullWidth
                                    name="note"
                                    value={note}
                                    onChange={handleChange}
                                />
                                :
                                <TextField
                                    variant="standard"
                                    type="text"
                                    multiline rows={4}
                                    fullWidth
                                    disabled
                                    name="note"
                                    value={note}
                                    onChange={handleChange}
                                />}
                            </Grid>
                            <Grid xs={12} sm={6} item style={{ paddingTop: '20px' }}>
                                <Button
                                    type="submit"
                                    varient="contained"
                                    color="primary"
                                    disabled={isDisabled}
                                    fullWidth
                                    onClick={(e) => {
                                        handleClick();
                                        handleSubmit(e);
                                    }}
                                >
                                    Submit
                                </Button>
                            </Grid>
                            <Grid xs={12} sm={6} item style={{ paddingTop: '20px' }}>
                                <Button
                                    type="submit"
                                    varient="contained"
                                    color="primary"
                                    fullWidth
                                    onClick={() => history.push("/orders")}
                                >
                                    GO BACK
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </CardContent>
            </Card>
        </div>
    )
}

export default EditOrderForm;