import React, { useContext } from "react";
import { AuthContext } from "../../context/AuthProvider";
import { Link } from "react-router-dom";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Box,
    Button,
    Paper,
    Grid
} from "@material-ui/core";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOrderItemModal from "../modal/DeleteOrderItemModal/DeleteOrderItemModal";
import { useState } from "react";
import { OrdersFormProvider } from "../../context/OrdersProvider";

const OrderItemsTable = ({ order, history }) => {

    const { userRole } = useContext(AuthContext);
    const [openPopup, setOpenPopup] = useState(false);
    const { orderItems, setOrderItems, handleCurrency } = useContext(OrdersFormProvider);

    const handleTotalAmount = () => {
        let totalAmount = 0;
        orderItems.forEach((orderItem) => {
            totalAmount += orderItem.price * orderItem.quantity;
        });

        return totalAmount;
    }

    const handleOpen = () => {
        setOpenPopup(true);
    };

    const handleClose = () => {
        setOpenPopup(false);
    }

    const columns = [
        { id: "ProductName", label: "Product Name", minWidth: 200 },
        {
            id: "Price",
            label: "Price",
            align: "left",
        },
        {
            id: "Quantity",
            label: "Quantity",
            align: "left",
        },
        {
            id: "Amount",
            label: "Amount",
            align: "left",
        },
        {
            id: "Actions",
            label: "Actions",
            align: "right",
        },
    ];

    return (
        <>
            <TableContainer component={Paper}>
                {/* <h2 style={{ paddingLeft: "80px", paddingTop: "20px" }}>
                    Puchase Order Id: ({order.puchaseOrderId})
                </h2> */}
                <Table aria-label="a dense table" className="product-table-container">
                    <TableHead>
                        <TableRow className="table__head">
                            {columns.map((column, i) => (
                                <TableCell
                                    key={column.id}
                                    className="table__head__cell"
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {orderItems &&
                            orderItems.map((orderItem, index) => {
                                return (
                                    <TableRow hover tabIndex={-1} key={index}>

                                        <TableCell>
                                            {orderItem.productName}
                                        </TableCell>

                                        <TableCell className="capitalize" align="left">
                                            <div style={{ display: "flex" }}>
                                                <h3>{orderItem.price}</h3>
                                                <h4 style={{ paddingLeft: "10px" }}>{handleCurrency(order.currency)}</h4>
                                            </div>
                                        </TableCell>

                                        <TableCell className="capitalize" align="left">
                                            <h3>{orderItem.quantity}</h3>
                                        </TableCell>

                                        <TableCell className="capitalize" align="left">
                                            <div style={{ display: "flex" }}>
                                                <h3>{orderItem.amount}</h3>
                                                <h4 style={{ paddingLeft: "10px" }}>{handleCurrency(order.currency)}</h4>
                                            </div>
                                        </TableCell>

                                        <TableCell align="right">
                                            <Box display="flex" justifyContent="flex-end">
                                                <Box ml={1}>
                                                    {userRole === "Admin" && order.status !== 2 ? (
                                                        <Button
                                                            className="button__edit"
                                                            startIcon={<EditOutlinedIcon className="edit-icon" />}
                                                            component={Link}
                                                            to={`/orderItems/update/${order.id}/${orderItem.id}`}
                                                        >
                                                        </Button>
                                                    )
                                                        :
                                                        (
                                                            <Button
                                                                className="button__edit"
                                                                startIcon={<EditOutlinedIcon />}
                                                                component={Link}
                                                                disabled
                                                                to={`/orderItems/update/${order.id}/${orderItem.id}`}
                                                            >
                                                            </Button>
                                                        )
                                                    }
                                                </Box>
                                                <Box ml={1}>
                                                    {userRole === "Admin" && order.status !== 2 ? (
                                                        <Button
                                                            onClick={() => {
                                                                handleOpen();
                                                            }}
                                                            className="button__delete"
                                                            startIcon={<DeleteOutlineIcon className="delete-icon" />}
                                                        >
                                                        </Button>
                                                    )
                                                        : (
                                                            <Button
                                                                onClick={() => {
                                                                    handleOpen();
                                                                }}
                                                                disabled
                                                                className="button__delete"
                                                                startIcon={<DeleteOutlineIcon />}
                                                            >
                                                            </Button>
                                                        )}
                                                    <DeleteOrderItemModal
                                                        openPopup={openPopup}
                                                        handleClose={handleClose}
                                                        setOrderItems={setOrderItems}
                                                        orderId={order.id}
                                                        orderItems={orderItems}
                                                        orderItemId={orderItem.id}
                                                        index={index}
                                                    />
                                                </Box>
                                            </Box>
                                        </TableCell>

                                    </TableRow>
                                );
                            })}

                    </TableBody>
                </Table>
                <Grid container >
                    <Grid xs={12} sm={6} item style={{ paddingTop: '20px' }}>
                        <Button
                            type="button"
                            varient="outlined"
                            color="primary"
                            fullWidth
                            onClick={() => history.push(`/orders`)}
                        >
                            GO BACK
                        </Button>
                    </Grid>

                    <Grid xs={12} sm={6} item style={{ textAlign: 'center', paddingTop: '20px', paddingBottom: '20px' }}>
                        <div style={{ display: "flex" }}>
                            <h2><b>Order Total</b> (<b>{handleTotalAmount()} </b>)</h2>
                            <h3 style={{ paddingLeft: "10px", paddingTop: "3px" }}>{handleCurrency(order.currency)}</h3>
                        </div>
                    </Grid>
                </Grid>
            </TableContainer>
        </>
    )
}

export default OrderItemsTable;