import React, { useContext, useEffect } from "react";
import { useMsal, useAccount } from "@azure/msal-react";
import { callMsGraph } from "../graph";
import { graphRequest, loginRequest } from "../authConfig";
import { AuthContext } from "../context/AuthProvider";
import { Route } from "react-router-dom";
import ProductsPage from "./ProductsPage/ProductsPage";
import EditProduct from "./EditProduct/EditProduct";
import CreateProduct from "./CreateProduct/CreateProduct";
import CreateProductType from "./CreateProductType/CreateProductType";
import ProductTypes from "./ProductTypes/ProductTypes";
import EditProductType from "./EditProductType/EditProductType";
import CategoryPage from "./CategoryPage/CategoryPage";
import CategoryForm from "../components/categoryForm/CategoryForm";
import EditCategoryModal from "../components/modal/EditCategoryModal/EditCategoryModal";
import OrdersPage from "./OrdersPage/OrdersPage";
import EditOrderForm from "../components/editOrderForm/EditOrderForm";
import CreateOrderForm from "../components/createOrderForm/CreateOrderForm";
import OrderItemPage from "./OrderItemPage/OrderItemPage";
import OrderItemCreateForm from "../components/orderItemCreateForm/OrderItemCreateForm";
import UpdateOrderItemForm from "../components/updateOrderItemForm/UpdateOrderItemForm";

const Main = () => {
  const { setToken, setUserRole, userRole, setUsers, token } = useContext(
    AuthContext
  );
  const { instance, accounts } = useMsal();

  const account = useAccount(accounts[0] || {});
  const requestProfileData = () => {
    if (account) {
      instance
        .acquireTokenSilent({
          ...loginRequest,
          account: account,
        })
        .then((response) => {
          // accesss token
          sessionStorage.setItem("token", response.accessToken);
          setToken(response.accessToken);

          // authority role
          let isAdmin = response.idTokenClaims.roles?.find(role => role === "Admin");
          if (!isAdmin)
            instance.logout(loginRequest);

          setUserRole(isAdmin);
          // setUserRole(response.idTokenClaims.roles[0]);
        });
    }
  };

  const requestUsers = () => {
    if (account) {
      instance
        .acquireTokenSilent({
          ...graphRequest,
          account: account,
        })
        // users
        .then((response) => {
          callMsGraph(response.accessToken).then((response) => {
            setUsers(response.value);
          });
        });
    }
  };

  useEffect(() => {
    requestProfileData();
    requestUsers();
  }, []);

  if (!token) return null;

  return (
    <div className="layout__body">
      <Route exact path="/" render={() => <ProductsPage />} />
      <Route exact path="/products" render={() => <ProductsPage />} />
      <Route exact path="/categories" render={() => <CategoryPage />} />
      <Route exact path="/orders" render={() => <OrdersPage />} />

      {userRole === "Admin" && (
        <>
          <Route
            path="/products/create"
            render={(props) => <CreateProduct {...props} />}
          />
          <Route
            exact
            path="/productTypes"
            render={(props) => <ProductTypes {...props} />}
          />
          <Route
            path="/productTypes/create"
            render={(props) => <CreateProductType {...props} />}
          />
          <Route
            path="/products/edit/:categoryId"
            render={(props) => <EditProduct {...props} />}
          />
          <Route
            path="/productTypes/edit/:productTypeId"
            render={(props) => <EditProductType {...props} />}
          />
          <Route
            path="/categories/create"
            render={(props) => <CategoryForm {...props} />}
          />
          <Route
            path="/categories/update/:categoryId"
            render={(props) => <EditCategoryModal {...props} />}
          />
          <Route
            path="/orders/update/:orderId"
            render={(props) => <EditOrderForm  {...props} />}
          />
          <Route
            path="/orders/create"
            render={(props) => <CreateOrderForm  {...props} />}
          />
          <Route
            path="/orders/orderItems/:orderId"
            render={(props) => <OrderItemPage  {...props} />}
          />
          <Route
            path="/orderItems/create/:orderId"
            render={(props) => <OrderItemCreateForm  {...props} />}
          />
          <Route
            path="/orderItems/update/:orderId/:orderItemId"
            render={(props) => <UpdateOrderItemForm  {...props} />}
          />
        </>
      )}
    </div>
  );
};

export default Main;
