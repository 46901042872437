import axios from "axios";
import { URL} from "../../config/config";
import { extractToken } from "../../helpers/helpers";
import { TOAST_ALERT } from "../../static/toastify/toastify";


// Create Category
export const createCategory = async (newCategory) => {
  await axios
    .post(`${URL}/Categories`, newCategory, {
      headers: {
        Authorization: `Bearer ${extractToken()}`,
        "Content-Type": "application/json",
      }
    })
    .then((res) => {
      TOAST_ALERT("Category Created", "success");
    })
    .catch((err) => {
      const [ error ] = err.response.data.errors.CategoryName;
      console.log(error);
      TOAST_ALERT(`${error}`, "error");
      throw err;
    });
};

//Delete Category
export const deleteCategoryById = async (deleteId) => {
  console.log(deleteId)
  let response;
  try {
    response = await axios.delete(`${URL}/Categories/${deleteId}`, {
      headers: {
        Authorization: `Bearer ${extractToken()}`,
      }
    });
    console.log(response)
  } catch (err) {
    const [ error ] = err.response.data.errors.CategoryName;
    console.log(err.response);
    TOAST_ALERT(`${error}`, "error");
    throw err;
  }
};

export const getAllCategoriesList = async () => {
  let res;
  try {
    res = await axios.get(`${URL}/Categories`, {
      headers: {
        Authorization: `Bearer ${extractToken()}`,
      },
    });
  } catch (err) {
    const [ error ] = err.response.data.errors.CategoryName;
    TOAST_ALERT(`${error}`, "error");
    throw err;
  }
  return res;
};

export const getCategoryById = async (categoryId) => {
  let res;
  try {
    res = await axios.get(`${URL}/Categories/${categoryId}`, {
      headers: {
        Authorization: `Bearer ${extractToken()}`,
      },
    });
  } catch (err) {
    const [ error ] = err.response.data.errors.CategoryName;
    TOAST_ALERT(`${error}`, "error");
    throw err;
  }
  return res;
};

export const updateCategory = async (category) => {
    await axios
    .put(`${URL}/Categories`, category, {
      headers: {
        Authorization: `Bearer ${extractToken()}`,
      },
    })
    .then((res) => {
      TOAST_ALERT("Category Updated", "success");
    })
    .catch((err) => {
      const [ error ] = err.response.data.errors.CategoryName;
      TOAST_ALERT(`${error}`, "error");
      throw err;
    });
};
