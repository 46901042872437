import React, { useContext, useEffect } from "react";
import CreateProductForm from "../../components/createProductForm/CreateProductForm";
import { FormProvider } from "../../context/FormProvider";
import { CircularProgress } from "@material-ui/core";
const CreateProduct = (props) => {
  const {
    getProductTypes,
    getDefaultType,
    setTags,
    setDynamicFields,
    loading,
    productTypes
  } = useContext(FormProvider);

  useEffect(() => {
    getProductTypes();
    getDefaultType();
    setTags([]);

    return () => setDynamicFields([], setTags([]));
  }, []);

  if (productTypes.length > 0 && loading) {
    return (
      <div className="loading_div">
        <div className="lds-dual-ring"></div>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="loading_div" style={{ paddingTop: '50px' }}>
        <h1>No Product Types Found!</h1>
      </div>
    );
  }

  return (
    <div className="layout__form">
      <CreateProductForm {...props} />
    </div>
  );
};

export default CreateProduct;
