import {
  Box,
  Button,
  makeStyles,
  Paper,
  Select,
  Typography,
} from "@material-ui/core";
import React, { useState, useContext, useEffect } from "react";
import { getAllProductTypesWithoutPagination } from "../../api/productTypeApi/productTypeApi";
import { processUpdateProduct } from "../../api/productsApi/productsApi";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { FormProvider } from "../../context/FormProvider";
import { TAGS } from "../../config/config";
import {
  updateProductFieldValue,
  createProductFieldValue,
} from "../../api/productsApi/productsApi";
import { getItemByProductId } from "../../api/itemsApi/itemsApi";
import Element from "../form/elements/Element";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import { TOAST_ALERT } from "../../static/toastify/toastify";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 3),
    boxShadow: "rgba(0, 0, 0, 0.27) 0px 10px 15px",
  },
  input: {
    marginTop: theme.spacing(1),
  },
  button: {
    marginLeft: "10px",
  },
}));

const EditProductForm = ({ editedProduct, history, fetchEditedProduct }) => {
  const {
    dynamicFields,
    handleType,
    setDynamicFields,
    setEditedProductId,
    setFieldsDynamicNoValue,
    fieldsDynamicNoValue,
    handleChangeNewCreatedFields,
    handleChange,
    tags,
  } = useContext(FormProvider);
  const classes = useStyles();
  const { instance } = useMsal();
  const [openField, setOpenField] = useState(true);
  const [itemNumber, setItemNumber] = useState(0);
  const [isDisabled, setIsDisabled] = useState(false);


  const [tagsFromEditedProduct, setTagsFromEditedProduct] = useState(
    editedProduct.tags && editedProduct.tags
  );

  useEffect(() => {
    checkIfItemsExists(editedProduct.id);
  });

  const processDynamicFieldValues = async () => {
    await getAllProductTypesWithoutPagination().then((res) => {
      const fields = res.data.find((el) => el.id === editedProduct.productTypeId);
      const newDynamicFields = fields.fieldDefinitions.map((element) => ({
        ...element,
        ...(editedProduct &&
          editedProduct.fieldValues.find(
            (el) => el.fieldDefinitionId === element.id
          )),
      }));
      const filteredDynamicFields = newDynamicFields.filter(
        (el) => el.fieldValue || el.fieldValue === "" || el.fieldValue === null
      );
      const filteredDynamicNoValueFields = newDynamicFields
        .filter((el) => el.fieldValue === undefined)
        .map((el) => {
          return {
            ...el,
            fieldDefinitonName: el.fieldName,
            fieldDefinitionId: el.id,
            fieldValue: "",
          };
        });

      setDynamicFields(filteredDynamicFields);
      setFieldsDynamicNoValue(filteredDynamicNoValueFields);
      setEditedProductId(editedProduct.id);
    });
  };
  useEffect(() => {
    processDynamicFieldValues();
    return () => {
      setDynamicFields([]);
    };
  }, [editedProduct]);

  const jsonValueToFormData = (product) => {
    let formData = new FormData();
    formData.append("productId", editedProduct.id);
    formData.append("fieldDefinitionId", product.fieldDefinitionId);
    formData.append("fieldValue", product.fieldValue);

    return formData;
  };

  const checkIfItemsExists = async (itemId) => {
    await getItemByProductId(itemId)
      .then((res) => {
        setItemNumber(res.data.length);
      })
  }

  const handleUpdateField = async (e, id) => {
    e.preventDefault();
    if (itemNumber > 0) {
      TOAST_ALERT("Cannot edit product, items exists", "error");
    }
    else {
      const editedProductField = dynamicFields.find((el) => el.id === id);

      // all other fields to be edited
      const updatedValue = {
        productId: editedProduct.id,
        fieldDefinitionId: editedProductField.fieldDefinitionId,
        fieldValue: editedProductField.fieldValue,
      };
      const updatedValueForm = jsonValueToFormData(updatedValue);
      await updateProductFieldValue(updatedValueForm, editedProduct.id);
      await processDynamicFieldValues();
      await fetchEditedProduct();
      setOpenField(false);
    }
  };
  const handleCreateField = async (e, id) => {
    e.preventDefault();
    const editedProductField = fieldsDynamicNoValue.find((el) => el.id === id);
    // if field to be edited is tags
    if (editedProductField.fieldType === TAGS) {
      const updateTags = {
        id: editedProduct.id,
        productTypeId: editedProduct.productTypeId,
        status: 1,
        tags: tags,
      };
      await processUpdateProduct(updateTags, editedProduct.id).catch((err) => {
        const message = err.message.split(" ");
        if (message[message.length - 1] === "401") {
          instance.logout(loginRequest);
        }
      });
    }
    else {
      const updatedValue = {
        productId: editedProduct.id,
        fieldDefinitionId: editedProductField.fieldDefinitionId,
        fieldValue: editedProductField.fieldValue,
      };
      const updatedValueForm = jsonValueToFormData(updatedValue);
      await createProductFieldValue(updatedValueForm);
    }
    await processDynamicFieldValues();
    await fetchEditedProduct();
    setOpenField(false);
  };

  const handleClick = () => {
    setIsDisabled(true);
};

  return (
    <Paper className={classes.root}>
      <Box id="edit__title" mb={5}>
        <Typography className="edit-product-span" variant="h5" component="h2">
          <span className="edit-product-span">Update Product</span>
        </Typography>
      </Box>
      <Select
        style={{ width: "100%" }}
        disabled={true}
        native
        onChange={(e) => handleType(e)}
      >
        <option value={editedProduct && editedProduct.productTypeName}>
          {editedProduct && editedProduct.productTypeName}
        </option>
      </Select>

      {editedProduct &&
        dynamicFields &&
        dynamicFields.map((elements) => {
          return (
            <form
              key={elements.id}
              style={{ display: "flex", flexDirection: "column" }}
            >
              <div className="product__input__container">
                {" "}
                <div className="product__input__left">
                  <Element
                    setTagsFromEditedProduct={setTagsFromEditedProduct}
                    tagsProps={tagsFromEditedProduct && tagsFromEditedProduct}
                    notEditing={false}
                    handleChange={handleChange}
                    openCreateField={false}
                    openField={openField}
                    elements={elements}
                  />
                </div>
                <div>
                  {openField === elements.id && (
                    <Button
                      className="submit__form__dynamic"
                      type="submit"
                      disabled={isDisabled}
                      startIcon={<AddIcon fontSize="small" className="edit-product-span"  />}
                      onClick={(e) => {
                        handleClick();
                        handleUpdateField(e, elements.id);
                      }}
                    >
                      <span className="edit-product-span">Update</span>
                      
                    </Button>
                  )}
                  {openField !== elements.id && (
                    <Button
                      className=""
                      startIcon={<EditIcon fontSize="small" className="edit-product-span"
                      />}
                      onClick={() => {
                        setIsDisabled(false);
                        setOpenField(elements.id);
                      }}
                    >
                      <span className="edit-product-span">Edit</span>
                    </Button>
                  )}
                </div>
              </div>
            </form>
          );
        })}
      {editedProduct &&
        fieldsDynamicNoValue &&
        fieldsDynamicNoValue.map((elements, i) => {
          return (
            <form
              key={elements.id}
              onSubmit={(e) => handleCreateField(e, elements.id)}
              style={{ display: "flex", flexDirection: "column" }}
            >
              <div className="product__input__container">
                {" "}
                <div className="product__input__left">
                  <Element
                    setTagsFromEditedProduct={setTagsFromEditedProduct}
                    tagsProps={tagsFromEditedProduct && tagsFromEditedProduct}
                    handleChange={handleChangeNewCreatedFields}
                    openCreateField={false}
                    openField={openField}
                    elements={elements}
                  />
                </div>
                <div >
                  {openField === elements.id && (
                    <Button
                      className=""
                      size="small"
                      type="submit"
                      startIcon={<AddIcon fontSize="small" />}
                    >
                      Update
                    </Button>
                  )}
                  {openField !== elements.id && (
                    <Button
                      className=""
                      id="product__edit__button"
                      startIcon={<EditIcon fontSize="small" />}
                      onClick={() => setOpenField(elements.id)}
                    >
                      Edit
                    </Button>
                  )}
                </div>
              </div>
            </form>
          );
        })}
      <div className="create__product__buttons">
        <Box ml={1}>
          <Button
            endIcon={<ArrowForwardIcon fontSize="small" className="edit-product-span" />}
            size="small"
            color="primary"
            onClick={() => history.push("/products")}
          >
            <span className="edit-product-span">Continue</span>
          </Button>
        </Box>
      </div>
    </Paper>
  );
};

export default EditProductForm;
