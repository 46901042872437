import { Button, Input, InputLabel } from '@material-ui/core';
import React, { Fragment } from 'react';
import { useState } from 'react';
import { useContext } from 'react';
import { defaultNumProductsPerPage } from '../../../config/config';
import { SearchContext } from '../../../context/SearchContextProvider';


const SearchFilterProductTypes = ({ setNumProductTypes, handleCancelQuery, handleSubmit, cancelDefaultQueryModel }) => {

    const { searchProductTypeQuery, setSearchProductTypeQuery } = useContext(SearchContext);
    const [isSearchClicked, setIsSearchClicked] = useState(true);

    const { name, categoryName } = searchProductTypeQuery;
    const handleSearch = (e) => {
        const { name, value } = e.target;
        setSearchProductTypeQuery({ ...searchProductTypeQuery, [name]: value });
    }

    return (
        <Fragment>
            <form style={{ display: "flex", paddingTop: "30px" }}>
                <div style={{ paddingRight: "20px" }}>
                    <InputLabel>
                        Product Type Name
                    </InputLabel>
                    <Input
                        variant="standard"
                        type="text"
                        fullWidth
                        value={name}
                        name="name"
                        onChange={handleSearch}
                    />
                </div>
                <div>
                    <InputLabel>
                        Category Name
                    </InputLabel>
                    <Input
                        variant="standard"
                        type="text"
                        fullWidth
                        value={categoryName}
                        name="categoryName"
                        onChange={handleSearch}
                    />
                </div>
            </form>
            <div style={{ textAlign: 'center', paddingTop: "25px" }}>
                {isSearchClicked === true ? (
                    <Button
                        variant="text"
                        size="large"
                        color="primary"
                        onClick={(e) => {
                            setIsSearchClicked(false);
                            handleSubmit(e);
                        }}
                    >
                        <span style={{ paddingRight: "10px", color: "#37A2DB" }}>+Search</span>
                    </Button>
                )
                    :
                    (
                        <Button
                            style={{ height: "40px" }}
                            size="large"
                            color="secondary"
                            variant="outlined"
                            onClick={(e) => {
                                setIsSearchClicked(true);
                                setSearchProductTypeQuery(cancelDefaultQueryModel);
                                handleCancelQuery(e);
                                setNumProductTypes(defaultNumProductsPerPage);
                            }}
                        >
                            <span>Cancel</span>
                        </Button>
                    )}
            </div>
        </Fragment>
    );
}

export default SearchFilterProductTypes;






