// Config object
export const msalConfig = {
  auth: {
    clientId: "0d44e6da-8e5b-4e98-94b5-5f02ce228647",
    authority: `https://login.microsoftonline.com/${"5c9b264f-33ad-4093-bb65-8d14aaec9f63"}`,
    redirectUri: "https://products.polarcape.com",
    postLogoutRedirectUri: "https://products.polarcape.com",
    // redirectUri: "http://localhost:3000",
    // postLogoutRedirectUri: "http://localhost:3000",
    navigateToLoginRequestUrl: false,
    cacheLocation: "sessionStorage",
  },
};

// Scopes
export const loginRequest = {
  scopes: ["api://0d44e6da-8e5b-4e98-94b5-5f02ce228647/app-scope"],
};
export const graphRequest = {
  scopes: ["User.Read"],
};

// Endpoints
export const graphConfig = {
  graphMeEndpoint: `https://graph.microsoft.com/v1.0/me`,
  graphUsersEndpoint: `https://graph.microsoft.com/v1.0/users?$top=300`,
};
