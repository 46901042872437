import React, { useContext, useEffect } from "react";
import { Chip, makeStyles, TextField } from "@material-ui/core";
import { FormProvider } from "../../../context/FormProvider";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CloseIcon from "@material-ui/icons/Close";
const useStyles = makeStyles((theme) => ({
  input: {
    marginTop: theme.spacing(1),
  },
}));

const MultipleTags = ({
  openCreateField,
  openField,
  id,
  tagsProps,
  fieldName,
  setTagsFromEditedProduct,
  required
}) => {
  const classes = useStyles();
  const { handleTags, tags, allTags, setTags } = useContext(FormProvider);

  useEffect(() => {
    if (tagsProps && tagsProps.length) {
      setTags(tagsProps);
      setTagsFromEditedProduct([]);
    }
  }, []);
  return (
    <Autocomplete
      disabled={openCreateField ? false : openField === id ? false : true}
      className="input__form"
      multiple
      options={allTags}
      getOptionLabel={(tags) => tags.name}
      freeSolo
      value={tags}

      onChange={(e, value) => handleTags(e, value)}
      renderTags={(tags, getTagProps) => {
        return tags.map((option, index) => (
          <Chip
            label={option.name}
            {...getTagProps({ index })}
            size="small"
            deleteIcon={<CloseIcon />}
            color="primary"
          />
        ));
      }}
      renderInput={(params) => (
        <TextField
          className="tag__chip"
          {...params}
          label={fieldName}
          className={classes.input}
          type="text"
          placeholder="Enter Tags"
          required={tags.length === 0}
        />
      )}
    />
  );
};

export default MultipleTags;
