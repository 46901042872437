import {
    Button,
    Typography,
    Grid,
    InputLabel,
    Card,
    CardContent,
    Input,
    Select,
    MenuItem
} from "@material-ui/core";
import React, { useState } from "react";
import { createOrder } from "../../api/ordersApi/ordersApi";
import OrderListDynamicTable from "../orderItemsDinamicTable/OrderListDynamicTable";
import { TOAST_ALERT } from "../../static/toastify/toastify";



const CreateOrderForm = (props) => {

    const orderForCreating = {
        supplier: "",
        deliveryAddress: "",
        createdOrderDate: "",
        deliveryOrderDate: "",
        currency: "",
        note: "",
        orderItems: []
    }
    const { history } = props;
    const [orderForCreate, setOrderForCreate] = useState(orderForCreating);
    const [orderItems, setOrderItems] = useState([]);
    const [isDisabled, setIsDisabled] = useState(false);

    orderForCreate.orderItems = orderItems;

    const handleCreateOrder = async (event) => {
        event.preventDefault();

        try {
            validateOrderItems();
            
            if(orderForCreate.currency === ""){
                TOAST_ALERT("Currency must not be empty!", "error");
            }
            await createOrder(orderForCreate);
            history.push(`/orders`);
        }
        catch (error) {
            console.log(error);
        }
    }


    const validateOrderItems = () => {
        orderItems.forEach((orderItem) => {

            if (orderItem.productName === "") {
                TOAST_ALERT("Product Name must not be empty!", "error");
            }

            if (orderItem.price === "") {
                TOAST_ALERT("Price must not be empty!", "error");
            }

            if (orderItem.quantity === "") {
                TOAST_ALERT("Quantity must not be empty!", "error");
            }
        });
    }

    
  const handleClick = () => {
    setIsDisabled(true);
  };

    const handleChange = (event) => {
        const { name, value } = event.target;

        setOrderForCreate((order) => {
            return { ...order, [name]: value }
        });
    };

    return (
        <Grid container>
            <Card style={{ maxWidth: 600, height: 800, margin: '0 auto', padding: '20px 20px' }}>
                <CardContent>
                    <Typography gutterBottom variant="h2" style={{ textAlign: 'center' }}>
                        Create Order
                    </Typography>
                    <form>
                        <Grid container>

                            <Grid xs={12} item style={{ paddingTop: '20px' }}>
                                <InputLabel required htmlFor="bootstrap-input" style={{ paddingBottom: '10px' }}>
                                    Delivery Address
                                </InputLabel>
                                <Input
                                    variant="standard"
                                    type="text"
                                    required
                                    name="deliveryAddress"
                                    fullWidth
                                    onChange={handleChange}
                                />
                            </Grid>

                            <Grid container>
                                <Grid xs={12} sm={6} item style={{ paddingTop: '50px', paddingRight: "20px" }}>
                                    <InputLabel required htmlFor="bootstrap-input" style={{ paddingBottom: '25px' }}>
                                        Created Order Date
                                    </InputLabel>
                                    <Input
                                        variant="standard"
                                        type="date"
                                        required
                                        fullWidth
                                        name="createdOrderDate"
                                        onChange={handleChange}
                                    />
                                </Grid>

                                <Grid xs={12} sm={6} item style={{ paddingTop: '50px' }}>
                                    <InputLabel required htmlFor="bootstrap-input" style={{ paddingBottom: '25px' }}>
                                        Delivery Order Date
                                    </InputLabel>
                                    <Input
                                        variant="standard"
                                        type="date"
                                        required
                                        fullWidth
                                        name="deliveryOrderDate"
                                        onChange={handleChange}
                                    />
                                </Grid>

                                <Grid xs={12} sm={6} item style={{ paddingTop: '50px', paddingRight: "20px" }}>
                                    <InputLabel required htmlFor="bootstrap-input" style={{ paddingBottom: '15px' }}>
                                        Suplier
                                    </InputLabel>
                                    <Input
                                        variant="standard"
                                        type="text"
                                        required
                                        fullWidth
                                        name="supplier"
                                        onChange={handleChange}
                                    />
                                </Grid>

                                <Grid xs={12} sm={6} item style={{ paddingTop: '50px' }}>
                                    <InputLabel required htmlFor="bootstrap-input">
                                        Currency
                                    </InputLabel>
                                    <Select
                                        style={{paddingTop: '15px'}}
                                        fullWidth
                                        value={orderForCreate.currency}
                                        variant="standard"
                                        name="currency"
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={1}>MKD</MenuItem>
                                        <MenuItem value={2}>SEK</MenuItem>
                                        <MenuItem value={3}>EUR</MenuItem>
                                    </Select>
                                </Grid>

                                <Grid xs={12} item style={{ paddingTop: '50px' }}>
                                    <InputLabel htmlFor="bootstrap-input" style={{ paddingBottom: '5px' }}>
                                        Note
                                    </InputLabel>
                                    <Input
                                        variant="standard"
                                        type="text"
                                        multiline rows={4}
                                        fullWidth
                                        name="note"
                                        onChange={handleChange}
                                    />
                                </Grid>

                            </Grid>
                            <Grid xs={12} sm={6} item style={{ paddingTop: '80px' }}>
                                <Button
                                    type="submit"
                                    varient="outlined"
                                    color="primary"
                                    fullWidth
                                    disabled={isDisabled}
                                    onClick={(e) => {
                                        handleClick();
                                        handleCreateOrder(e);
                                    }}
                                >
                                    Submit
                                </Button>
                            </Grid>
                            <Grid xs={12} sm={6} item style={{ paddingTop: '80px' }}>
                                <Button
                                    type="button"
                                    varient="outlined"
                                    color="primary"
                                    fullWidth
                                    onClick={() => history.push("/orders")}
                                >
                                    GO BACK
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </CardContent>
            </Card>

            <Card style={{ maxWidth: 700, margin: '0 auto', padding: '20px 20px' }}>
                <CardContent>
                    <Typography gutterBottom variant="h2" style={{ textAlign: 'center' }}>
                        Order Items
                    </Typography>
                    <Grid xs={12} sm={6} item>

                    </Grid>
                    <OrderListDynamicTable setOrderItems={setOrderItems} />
                </CardContent>
            </Card>

        </Grid>
    )
}

export default CreateOrderForm;