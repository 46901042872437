export const productTypes = [
  {
    id: 1,
    type: "Book",
  },
  {
    id: 2,
    type: "Video",
  },
  {
    id: 3,
    type: "Tablet",
  },
  {
    id: 4,
    type: "Laptop",
  },
  {
    id: 5,
    type: "Monitor",
  },
];

export const staticInputs = [
  { id: 1, fieldName: "Name", fieldType: "text", required: true },
  { id: 2, fieldName: "Quantity", fieldType: "number", required: true },
  { id: 3, fieldName: "Tags", fieldType: "multiple", required: false },
];

export const dynamicInputs = {
  type: "Book",
  fields: [
    {
      id: 1,
      fieldName: "Name",
      fieldType: "text",
      required: true,
      fieldValue: "aaaaaaa",
    },
    {
      id: 2,
      fieldName: "Quantity",
      fieldType: "number",
      required: true,
      fieldValue: "aaaaaaa",
    },
    {
      id: 3,
      fieldName: "Author",
      fieldType: "text",
      required: false,
      fieldValue: "",
    },
    {
      id: 4,
      fieldName: "Tags",
      fieldType: "tags",
      required: false,
      fieldValue: "",
    },
    {
      id: 6,
      fieldName: "Image",
      fieldType: "password",
      required: false,
      fieldValue: "",
    },
    {
      id: 7,
      fieldName: "Image",
      fieldType: "email",
      required: false,
      fieldValue: "",
    },
    {
      id: 8,
      fieldName: "Image",
      fieldType: "textarea",
      required: false,
      fieldValue: "",
    },
  ],
};

export const productFieldNames = [
  {
    id: 1,
    fieldType: 1,
    //TEXT
  },
  {
    id: 2,
    fieldType: 2,
    //NUMBER
  },
  {
    id: 3,
    fieldType: 3,
    //decimal
  },
  {
    id: 4,
    fieldType: 4,
    //date
  },
  {
    id: 5,
    fieldType: 5,
    //datetime
  },
  {
    id: 6,
    fieldType: 6,
    //time
  },
  {
    id: 7,
    fieldType: 7,
    //boolean
  },
  {
    id: 8,
    fieldType: 8,
    //textarea
  },
  {
    id: 9,
    fieldType: 9,
    //file
  },
];

