import { React, useState, useEffect } from "react";
import { getAllCategoriesList } from "../../api/categoryApi/categoryApi";
import CategoryTable from "../../components/categoryTable/categoryTable";
import { TOAST_ALERT } from "../../static/toastify/toastify";


const CategoryPage = () => {
    const [categories, setCategories] = useState([]);
    const [isToggled, setIsToggled] = useState(false);
    const [categoryLength, setCategoryLength] = useState(false);

    const displayAllCategories = async () => {
        try {
            await getAllCategoriesList().then((res => {
                setCategories(res.data);
                if (res.data.length > 0) {
                    setCategoryLength(!categoryLength);
                }
                else setCategoryLength(categoryLength);
            }));
        } catch (error) {
            TOAST_ALERT(error, 'error');
            console.log(error);
        }
    }

    useEffect(() => {
        displayAllCategories();
    }, []);

    return (
        <div>
            {!isToggled && 
            <CategoryTable 
                categories={categories} 
                setCategories={setCategories}
                setIsToggled={setIsToggled}
                isToggled={isToggled}
                categoryLength={categoryLength}
                />}
        </div>
    );
};

export default CategoryPage;
