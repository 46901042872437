import React from "react";
import { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../context/AuthProvider";
import { Button, } from "@material-ui/core";
import { Link } from "react-router-dom";
import OrderItemsTable from "../../components/orderItemsTable/OrderItemsTable";
import { OrdersFormProvider } from "../../context/OrdersProvider";


const OrderItemPage = (props) => {

    const { orderId } = props.match.params;
    const { history } = props;
    const [ orderTotalAmount, setOrderTotalAmount ] = useState(0);
    const { fetchOrder, order } = useContext(OrdersFormProvider);

    useEffect(() => {
        fetchOrder(orderId);
        setOrderTotalAmount(order.totalAmount);
    }, []);


    const { userRole } = useContext(AuthContext);
   
    return (
        <div className="product-page">
            <div className="product-page-dropdown-create">
                <div className="product-page-headline">
                    <h1>Order Items</h1>
                </div>
                <div className="product-page-data-info">
                </div>
                <div className="product-page-add-btn">
                    {userRole === "Admin" && order.status !== 2 ? (
                        <Button
                            id="create__product__button"
                            variant="text"
                            size="large"
                            component={Link}
                            to={`/orderItems/create/${orderId}`}
                        >
                            <span className="add-new-product-span">+Add new order item</span>
                        </Button>
                    ) : (
                        <Button
                            id="create__product__button"
                            variant="text"
                            size="large"
                            disabled
                            component={Link}
                            to={`/orderItems/create/${orderId}`}
                        >
                            <span style={{ color: "gray"}}>+Add new order item</span>
                        </Button>
                    )}
                </div>
            </div>
            <OrderItemsTable
                orderTotalAmount={orderTotalAmount}
                setOrderTotalAmount={setOrderTotalAmount}
                order={order}
                history={history}
            />
        </div>
    );
};

export default OrderItemPage;