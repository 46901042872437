import React, { createContext, useState } from "react";
export const AuthContext = createContext({});

export const AuthContextProvider = (props) => {
  const [token, setToken] = useState(null);
  const [userRole, setUserRole] = useState(undefined);
  const [users, setUsers] = useState([]);

  const values = {
    token,
    userRole,
    users,
    setToken,
    setUserRole,
    setUsers,
  };
  return (
    <AuthContext.Provider value={values}>{props.children}</AuthContext.Provider>
  );
};

export default AuthContextProvider;
