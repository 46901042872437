import "./App.css";
import React, { useState } from "react";
// MSAL
import {
  MsalProvider,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import { PublicClientApplication } from "@azure/msal-browser";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import FormContextProvider from "./context/FormProvider";
import OrderContextProvider from "./context/OrdersProvider";
import SearchContextProvider from "./context/SearchContextProvider";
import TagsContextProvider from "./context/TagsContextProvider";
import { AuthContextProvider } from "./context/AuthProvider";
import ModalContextProvider from "./context/ModalContextProvider";
import Main from "./pages/Main";
import Header from "./components/header/Header";
import SignIn from "./components/signIn/SignIn";
import ResponsiveHeader from "./components/responsiveHeader/ResponsiveHeader";
import ScrollToTop from "./components/scrollToTop/ScrollToTop";
import { ThemeProvider } from "@material-ui/styles";
import { CssBaseline, createMuiTheme } from "@material-ui/core";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  // MSAL
  const msalInstance = new PublicClientApplication(msalConfig);

  const [mode, setMode] = useState("light");

  const theme = createMuiTheme({
    palette: {
      type: mode,
    },
  });

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <MsalProvider instance={msalInstance}>
            <UnauthenticatedTemplate>
              <Route path="/" render={() => <SignIn />} />
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
              <AuthContextProvider>
                <SearchContextProvider>
                  <TagsContextProvider>
                    <FormContextProvider>
                      <OrderContextProvider>
                        <ModalContextProvider>
                          <Header mode={mode} setMode={setMode} />
                          <div className="layout">
                            <div className="layout__inner">
                              <ResponsiveHeader mode={mode} setMode={setMode} />
                              <Switch>
                                <ScrollToTop>
                                  <ToastContainer />
                                  <Main />
                                </ScrollToTop>
                              </Switch>
                            </div>
                          </div>
                        </ModalContextProvider>
                      </OrderContextProvider>
                    </FormContextProvider>
                  </TagsContextProvider>
                </SearchContextProvider>
              </AuthContextProvider>
            </AuthenticatedTemplate>
          </MsalProvider>
        </Router>
      </ThemeProvider>
    </div>
  );
}

export default App;
