import React from "react";
import { deleteCategoryById } from "../../../api/categoryApi/categoryApi";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../authConfig";
import { Button, Box, Paper } from "@material-ui/core/";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";
import { TOAST_ALERT } from "../../../static/toastify/toastify";

const DeleteCategoryModal = ({
  deleteId,
  setIsOpenDelete,
  categories,
  setCategories
}) => {
  
  const { instance } = useMsal();

  const handleDelete = async () => {
    await deleteCategoryById(deleteId)
      .then(() => {
        setIsOpenDelete(false);
        handleCategoryDelete();
      })
      .catch((err) => {
        TOAST_ALERT("Default category can not bet deleted!", "error");
        setIsOpenDelete(false);
        const message = err.message.split(" ");
        if (message[message.length - 1] === "401") {
          instance.logout(loginRequest);
        }
      });
  };

  const handleCategoryDelete = () => {
      let filteredCategories = categories.filter(category => category.id !== deleteId);
      setCategories(filteredCategories);
  }

  return (
    <Paper>
      <div className="modal">
        <div className="modal__header">
          <h3>Are you sure?</h3>
        </div>
        <div className="modal__btn__group">
          <div className="modal__btn__1">
            <Box ml={1}>
              <Button 
                className="delete-category-btn"
                size="small"
                color="primary"
                startIcon={<CloseIcon fontSize="small" className="category-form-btn-color" />}
                onClick={() => setIsOpenDelete(false)}
              >
               <span className="category-form-btn-color">Cancel</span> 
              </Button>
            </Box>
          </div>
          <div className="modal__btn__2">
            <Box ml={1}>
              <Button
                onClick={() => {
                  handleDelete();
                }}
                color="secondary"
                size="small"
                className="button__delete"
                startIcon={<DeleteIcon fontSize="small" />}
              >
                Delete
              </Button>
            </Box>
          </div>
        </div>
      </div>
    </Paper>
  );
};

export default DeleteCategoryModal;
