import React, { createContext, useState } from "react";

export const ModalContext = createContext();

const ModalContextProvider = (props) => {
  const [responsiveHeaderStatus, setResponsiveHeaderStatus] = useState(false);

  const values = {
    responsiveHeaderStatus,
    setResponsiveHeaderStatus,
  };
  return (
    <ModalContext.Provider value={values}>
      {props.children}
    </ModalContext.Provider>
  );
};

export default ModalContextProvider;
