import { React, useState } from "react";
import { Button, TextField, Card, Typography, CardContent, Grid } from "@material-ui/core";
import { createCategory } from "../../api/categoryApi/categoryApi";
import { Link } from 'react-router-dom';

const defaultFormFields = {
    categoryName: " ",
}

const CategoryForm = (props) => {
    const [categoryFormFields, setCategoryFormFields] = useState(defaultFormFields);
    const [isDisabled, setIsDisabled] = useState(false);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setCategoryFormFields({ ...categoryFormFields, [name]: value });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            await createCategory(categoryFormFields);
            props.history.push("/categories");

        } catch (error) {
            console.log(error);
        }
    }
    const handleClick = () => {
        setIsDisabled(true);
      };

    return (
        <div>
            <Card style={{ maxWidth: 600, margin: '0 auto', padding: '20px 20px' }}>
                <CardContent>
                    <Typography gutterBottom variant="h3" style={{ textAlign: 'center' }}>
                        Create Category
                    </Typography>
                    <Grid container>
                        <Grid xs={12} item style={{ paddingTop: '20px', paddingRight: '20px' }}>
                            <form style={{ paddingLeft: "80px" }}>
                                <TextField
                                    label="Category name"
                                    type="text"
                                    required
                                    fullWidth
                                    onChange={handleChange}
                                    name="categoryName"
                                    variant="standard"
                                />
                            </form>
                        </Grid>
                        <Grid xs={12} sm={6} item style={{ paddingTop: "20px" }}>
                            <Button
                                variant="text"
                                size="large"
                                color="primary"
                                varient="outlined"
                                fullWidth
                                component={Link}
                                to="/categories"
                            >
                                <span className="category-form-btn-color">&lt;Go Back</span>
                            </Button>
                        </Grid>
                        <Grid xs={12} sm={6} item style={{ paddingTop: "20px" }}>
                            <Button
                                type="submit"
                                disabled={isDisabled}
                                size="large"
                                varient="outlined"
                                color="primary"
                                fullWidth
                                onClick={(e) => {      
                                    handleClick(false);  
                                    handleSubmit(e);
                                }}
                            >
                                <span className="category-form-btn-color">+Add</span>
                            </Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </div>
    );
};

export default CategoryForm;
