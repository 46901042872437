import React, { useContext, createContext, useState } from "react";
import { TagsContext } from "./TagsContextProvider";
import { createProduct } from "../api/productsApi/productsApi";
import {
  getAllProductTypesWithoutPagination,
  getProductTypeData,
} from "../api/productTypeApi/productTypeApi";
import { loginRequest } from "../authConfig";
import { useMsal } from "@azure/msal-react";

export const FormProvider = createContext({});

export const FormContextProvider = (props) => {
  const { instance } = useMsal();
  const [dynamicFields, setDynamicFields] = useState([]);
  const [fieldsDynamicNoValue, setFieldsDynamicNoValue] = useState([]);
  const [type, setType] = useState("");
  const { tags: allTags } = useContext(TagsContext);
  const [tags, setTags] = useState([]);
  const [productTypes, setProductTypes] = useState([]);
  const [editedProductId, setEditedProductId] = useState("");
  const [loading, setLoading] = useState(true);
  const [selectedFile, setSelectedFile] = useState({});

  const getProductTypes = async () => {
    await getAllProductTypesWithoutPagination()
      .then(({ data }) => {
        setProductTypes(data);
      })
      .catch((err) => {
        const message = err.message.split(" ");
        if (message[message.length - 1] === "401") {
          instance.logout(loginRequest);
        }
      });
  };

  const getProductType = async (type) => {
    await getProductTypeData(type)
      .then(({ data }) => {
        setDynamicFields(data.fieldDefinitions);
        setType(data.productTypeName);
      })
      .catch((err) => {
        const message = err.message.split(" ");
        if (message[message.length - 1] === "401") {
          instance.logout(loginRequest);
        }
      });
  };
  
  const getDefaultType = async () => {
    let types;
    setLoading(true);
    await getAllProductTypesWithoutPagination()
      .then(({ data }) => {
        types = data[0].productTypeName;
        setType(data[0].productTypeName);
        setLoading(false);
      })
      .catch((err) => {
        const message = err.message.split(" ");
        if (message[message.length - 1] === "401") {
          instance.logout(loginRequest);
        }
      });
    await getProductType(types);
  };

  const handleType = async (e) => {
    await getProductType(e.target.value);
    await getProductTypes();
  };

  const handleChange = (e, id) => {
    const fields = dynamicFields.map((el) => {
      if (el.id === id) {
        el["fieldValue"] = e.target.value;
        el["fieldName"] = e.target.name;
      }
      return el;
    });
    fields.map((el) => {
      if (el.fieldName === "Quantity" && el.fieldValue < 0) {
        el.fieldValue = 0;
      }
    });
    setDynamicFields(fields);
  };

  const handleChangeNewCreatedFields = (e, id) => {
    const fields = fieldsDynamicNoValue.map((el) => {
      if (el.id === id) {
        el["fieldValue"] = e.target.value;
        el["fieldName"] = e.target.name;
      }

      return el;
    });
    setFieldsDynamicNoValue(fields);
  };

  const handleTags = (_, value) => {
    const newTags = value.map((el) => (el.name ? el : { name: el }));

    let filteredTags = Object.values(
      newTags.reduce((c, e) => {
        if (!c[e.name]) c[e.name] = e;
        return c;
      }, {})
    );
    setTags(filteredTags);
  };

  const handleImage = (e, id) => {
    let imageData = e.target.files[0];
    setSelectedFile(imageData);
  };

  const productModel = (dynamicFields, productTypes, type, tags) => {
    const fieldValues = dynamicFields
      .map((el) => {
        return {
          fieldDefinitionId: el.id,
          fieldValue: el.fieldValue,
          fieldDefinitionName: el.fieldName,
          fieldType: el.fieldType,
        };
      })
      .filter((el) => el.fieldType !== 9)
      .filter((el) => el.fieldType !== 5);

    const productTypeObj = productTypes.find((el) => el.productTypeName === type);

    const filteredTags = tags.map((el) => {
      return {
        name: el.name,
      };
    });

    const modifiedProduct = {
      productTypeId: productTypeObj.id,
      tags: filteredTags,
      fieldValues,
    };

    return modifiedProduct;
  };

  const jsonToFormData = (product) => {
    let formData = new FormData();

    formData.append("ProductTypeId", product.productTypeId);

    product.tags.forEach((tag, i) => {
      if (tag.id) {
        formData.append(`Tags[${i}].Id`, tag.id);
      }
      formData.append(`Tags[${i}].Name`, tag.name);
    });

    product.fieldValues.forEach((field, i) => {
      formData.append(
        `FieldValues[${i}].FieldDefinitionId`,
        field.fieldDefinitionId
      );
      formData.append(
        `FieldValues[${i}].FieldDefinitionName`,
        field.fieldDefinitionName
      );
      formData.append(`FieldValues[${i}].FieldValue`, field.fieldValue);
    });

    if (selectedFile) {
      formData.append(`Image`, selectedFile);
    }

    return formData;
  };

  // create product Form
  const handleSubmit = async (e) => {
    const createdProduct = productModel(dynamicFields, productTypes, type, tags);
    const modifiedProduct = jsonToFormData(createdProduct);
    await createProduct(modifiedProduct);
    setDynamicFields([]);
    setTags([]);
  };

  const values = {
    handleChange,
    handleSubmit,
    handleTags,
    handleType,
    getProductTypes,
    getProductType,
    getDefaultType,
    setTags,
    setDynamicFields,
    setType,
    setEditedProductId,
    setFieldsDynamicNoValue,
    handleChangeNewCreatedFields,
    setProductTypes,
    getProductTypes,
    handleImage,
    fieldsDynamicNoValue,
    dynamicFields,
    tags,
    allTags,
    type,
    productTypes,
    loading,
    dynamicFields,
    type,
    selectedFile,
  };
  return (
    <FormProvider.Provider value={values}>
      {props.children}
    </FormProvider.Provider>
  );
};

export default FormContextProvider;
