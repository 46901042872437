import axios from "axios";
import { URL, GET_ORDERS } from "../../config/config";
import { extractToken } from "../../helpers/helpers";
import { TOAST_ALERT } from "../../static/toastify/toastify";



export const getAllOrdersList = async () => {
  let res;
  try {
    res = await axios(`${URL}/Orders?PageNumber=1&PageSize=100`, {
      headers: {
        Authorization: `Bearer ${extractToken()}`,
      },
    });
  } catch (err) {
    // TOAST_ALERT("Error occured", "error");
    throw err;
  }

  return res;
};

export const getOrders = async (path, page, size, searchQuery) => {

  const {
    deliveryAdress,
    createdDateBefore,
    createdDateAfter,
    deliveredDateBefore,
    deliveredDateAfter,
    supplier,
    totalAmountLessOrEqual,
    totalAmountGreatOrEqual
  } = searchQuery;
  
  try {
    let response;
    response = await axios.get(
      `${URL}/${path}`,
      { params: {
        pageNumber: page,
        pageSize: size,
        deliveryAdress: deliveryAdress,
        createdDateBefore: createdDateBefore,
        createdDateAfter: createdDateAfter,
        deliveredDateBefore: deliveredDateBefore,
        deliveredDateAfter: deliveredDateAfter,
        supplier: supplier,
        totalAmountLessOrEqual: totalAmountLessOrEqual,
        totalAmountGreatOrEqual: totalAmountGreatOrEqual
      }},
      {
        headers: {
          Authorization: `Bearer ${extractToken()}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.log(error.message);
    throw error;
  }
};

export const deleteOrderById = async (deleteId) => {
  let response;
  try {
    response = await axios.delete(`${URL}/${GET_ORDERS}/${deleteId}`, {
      headers: {
        Authorization: `Bearer ${extractToken()}`,
      },
    });
    if (response.data === false) {
      TOAST_ALERT("Order does not exist!", "error");
    } else {
      TOAST_ALERT("Order Deleted", "success");
    }
  } catch (error) {
    TOAST_ALERT("Error occured", "error");
    throw error;
  }
};

export const updateOrder = async (orderForUpdating) => {
  await axios
    .put(`${URL}/${GET_ORDERS}`, orderForUpdating, {
      headers: {
        Authorization: `Bearer ${extractToken()}`
      }
    })
    .then((res) => {
      TOAST_ALERT("Order Edited", "success");
    })
    .catch((err) => {
      TOAST_ALERT("Error occured", "error");
      throw err;
    });
};

export const getSingleOrder = async (orderId) => {
  let res;
  try {
    res = await axios.get(`${URL}/${GET_ORDERS}/${orderId}`, {
      headers: {
        Authorization: `Bearer ${extractToken()}`,
      },
    });
  } catch (err) {
    TOAST_ALERT("Error occured", "error");
    throw err;
  }
  return res;
};

export const createOrder = async (orderForCreating) => {
  await axios
    .post(`${URL}/${GET_ORDERS}`, orderForCreating, {
      headers: {
        Authorization: `Bearer ${extractToken()}`
      },
    })
    .then((res) => {
      TOAST_ALERT("Order Created", "success");
    })
    .catch((err) => {
      const [error] = err.response.data.errors.OrderItems;
      TOAST_ALERT(`${error}`, "error");
      throw err;
    });
};

export const createOrderItem = async (orderItemForCreating) => {
  await axios
    .post(`${URL}/${GET_ORDERS}/addOrderItem`, orderItemForCreating, {
      headers: {
        Authorization: `Bearer ${extractToken()}`
      },
    })
    .then((res) => {
      TOAST_ALERT("Order Created", "success");
    })
    .catch((err) => {
      TOAST_ALERT("Error occured", "error");
      throw err;
    });
};

export const updateOrderItem = async (orderItemForUpdating) => {
  await axios
    .put(`${URL}/${GET_ORDERS}/updateOrderItem`, orderItemForUpdating, {
      headers: {
        Authorization: `Bearer ${extractToken()}`
      }
    })
    .then((res) => {
      TOAST_ALERT("Order Item Edited", "success");
    })
    .catch((err) => {
      TOAST_ALERT("Error occured", "error");
      throw err;
    });
};

export const deleteOrderItemById = async (orderId, orderItemId) => {
  let response;
  try {
    response = await axios.delete(`${URL}/${GET_ORDERS}/${orderId}/${orderItemId}`, {
      headers: {
        Authorization: `Bearer ${extractToken()}`,
      },
    });
    if (response.data === false) {
      TOAST_ALERT("Order item does not exist!", "error");
    } else {
      TOAST_ALERT("Order item Deleted", "success");
    }
  } catch (error) {
    TOAST_ALERT("Error occured", "error");
    throw error;
  }
};
