import { proccessFieldType } from "../../../helpers/helpers";
import TextFieldInput from "../elements/TextField";
import DatePicker from "./DatePicker";
import Input from "./Input";
import InputFile from "./InputFile";
import MultipleTags from "./MultipleTags";

const Element = ({
  openField,
  openCreateField,
  handleChange,
  tagsProps,
  setTagsFromEditedProduct,
  handleImage,
  elements: { fieldName, fieldType, isRequired, id, fieldValue },
}) => {
  switch (proccessFieldType(fieldType)) {
    case "text":
    case "number":
      return (
        <Input
          handleChange={handleChange}
          fieldType={fieldType && fieldType}
          required={isRequired && isRequired}
          fieldName={fieldName && fieldName}
          id={id && id}
          fieldValue={fieldValue && fieldValue}
          openField={openField}
          openCreateField={openCreateField}
        />
      );

    case "date":
      return (
        <DatePicker
          handleChange={handleChange}
          fieldType={fieldType && fieldType}
          required={isRequired && isRequired}
          fieldName={fieldName && fieldName}
          id={id && id}
          fieldValue={fieldValue && fieldValue}
          openField={openField}
          openCreateField={openCreateField}
        />
      );

    case "file":
      return (
        <InputFile
          handleChange={handleImage}
          fieldType={fieldType && fieldType}
          required={isRequired && isRequired}
          fieldName={fieldName && fieldName}
          id={id && id}
          openField={openField}
          openCreateField={openCreateField}
        />
      );

    case "tags":
      return (
        <MultipleTags
          setTagsFromEditedProduct={setTagsFromEditedProduct}
          fieldName={fieldName}
          tagsProps={tagsProps}
          openField={openField}
          openCreateField={openCreateField}
          id={id && id}
          required={isRequired && isRequired}
        />
      );

    case "textarea":
      return (
        <TextFieldInput
          fieldType={fieldType && fieldType}
          required={isRequired && isRequired}
          fieldName={fieldName && fieldName}
          id={id && id}
          handleChange={handleChange}
          fieldValue={fieldValue && fieldValue}
          openField={openField}
          openCreateField={openCreateField}
        />
      );
  }

  return null;
};

export default Element;
