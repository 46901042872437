export const defaultStartingPage = 1;
export const defaultNumProductsPerPage = 5;
export const URL = `https://cave-dotnet.polarcape.com/api`;
export const PC_CAVE_URL = `https://cave-python.polarcape.com`;
export const IMAGE__URL = `https://cave-dotnet.polarcape.com`;
// export const URL = `https://localhost:44343/api`;
// export const PC_CAVE_URL = `http://localhost:8000`;
// export const IMAGE__URL = `https://localhost:44343/`;
export const GET_PRODUCTS = "Products";
export const GET_ORDERS = "Orders";
export const TYPE_DEFINITION = "FieldDefinitions";
export const GET_RENTALS = "Rent/history";
export const BORROW = "Rent/borrow";
export const RETURN = "Rent/return";
export const GET_BORROWED = "Rent/borrowed";
export const PRODUCT_TYPE = "ProductTypes";
export const borrowedProduct = 1;
export const dropdownValues = [5, 10, 15, 20];
export const TAGS = 5;
export const IMAGE = 9;
export const NOT_AVAILABLE = 2;
