import React from "react";
import Pagination from "@material-ui/lab/Pagination";

const PaginationComponent = ({ page, paginationChange, paginationProductTypeChange, paginationOrderChange, totalPages, path }) => {
  return (
    <div id="pagination"
    className="pagination">
      <Pagination
        className="Pag"
        onChange={(e, selectedPage) => {

          if(path === "Orders"){
            paginationOrderChange(selectedPage, path);
          }
          else if(path === "Products"){
            paginationChange(selectedPage, path);
          }
          else if(path === "ProductTypes"){
            paginationProductTypeChange(selectedPage, path);
          }
          
        }}
        page={page}
        count={totalPages}
        size="medium"
        shape="rounded"
      />
    </div>
  );
};

export default PaginationComponent;
