import { Dialog, DialogContent, DialogTitle, Button, Box } from '@material-ui/core';
import CloseIcon from "@material-ui/icons/Close";
import React from 'react';

const OrderNotePopup = (props) => {

    const { note, openPopup, handleClose} = props;

    return (
        <div>
            <Dialog
                open={openPopup}
                onClose={handleClose}
                BackdropProps={{ style: { backgroundColor: "transparent", boxShadow: 'none', } }}
            >
                <DialogTitle style={{ textAlign: 'center' }}>
                    <div>Note Description</div>
                </DialogTitle>

                <DialogContent style={{ width: "500px", height: "200px" }}>
                    <div><b>{note}</b></div>
                </DialogContent>

                <Box ml={1} style={{ textAlign: 'center'}}>
                    <Button
                        size="small"
                        color="primary"
                        open={openPopup}
                        startIcon={<CloseIcon fontSize="small" className="category-form-btn-color" />}
                        onClick={handleClose}
                    >
                        <span className="category-form-btn-color">Close</span>
                    </Button>
                </Box>
            </Dialog>
        </div>
    );
}

export default OrderNotePopup;