import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { createProductType } from "../../api/productTypeApi/productTypeApi";
import { getAllCategoriesList } from "../../api/categoryApi/categoryApi";
import { productFieldNames } from "../../static/db/db";
import { TOAST_ALERT } from "../../static/toastify/toastify";
import ProductTypeDynamicTable from "../../components/productTypeDynamicTable/ProductTypeDynamicTable";
import CreateProductTypeForm from "../../components/productTypeForm/CreateProductTypeForm";
import { Button, Input, Paper, FormControl, InputLabel, Select, MenuItem, Option } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

const CreateProductType = (props) => {
  const [categories, setCategories] = useState([]);
  const [type, setType] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const { history } = props;
  const [dynamicFields, setDinamicFields] = useState([
    {
      id: uuidv4(),
      fieldName: "Name",
      fieldType: 1,
      isRequired: true,
      searchable: true,
      unique: false,
      countable: false,
    },
  ]);
  const [editMode, setEditMode] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");

  const [newFieldType, setNewFieldType] = useState({
    fieldName: "",
    fieldType: 1,
    isRequired: false,
    searchable: false,
    unique: false,
  });

  const handleChange = (e) => {
    const target = e.target;
    let value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    if (name === "fieldType") {
      value = Number(value);
    }

    setNewFieldType({
      ...newFieldType,
      id: newFieldType.id || uuidv4(),
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const editedTypeIndex = dynamicFields.findIndex(
      (type) => type.id === newFieldType.id
    );
    if (editMode) {
      let list = dynamicFields;
      list[editedTypeIndex] = newFieldType;
      setDinamicFields(list);
      setNewFieldType({
        fieldName: "",
        fieldType: 1,
        isRequired: false,
        searchable: false,
        unique: false,
      });
    } else {
      const index = dynamicFields.findIndex(
        (el) => el.fieldName === newFieldType.fieldName
      );

      if (index === -1) {
        setDinamicFields([...dynamicFields, newFieldType]);
        setNewFieldType({
          fieldName: "",
          fieldType: 1,
          isRequired: false,
          searchable: false,
          unique: false,
        });
        TOAST_ALERT("Field Created", "success");
      } else {
        TOAST_ALERT("Field Name already exists", "error");
      }
    }
    setEditMode(false);
  };

  const handleDeleteType = (id) => {
    const filtered = dynamicFields.filter((type) => type.id !== id);
    setDinamicFields(filtered);
    TOAST_ALERT("Field Deleted", "success");
  };

  const handleUpdateType = (id) => {
    const editedType = dynamicFields.find((type) => type.id === id);
    setNewFieldType(editedType);
    setEditMode(true);
  };

  const createProductTypeAsync = async (createdType) => {
    await createProductType(createdType)
      .then((result) => {
        if (result.data) {
          history.push("/productTypes");
        }
      });
  };

  const createNewType = async () => {
    const fieldDefinitions = dynamicFields.map((type) => {
      return {
        fieldName: type.fieldName,
        fieldType: type.fieldType,
        isRequired: type.isRequired,
        searchable: type.searchable,
        unique: type.unique,
      };
    });

    const createdType = {
      fieldDefinitions,
      productTypeName: type,
      categoryId: selectedCategory
    };
    if (type && selectedCategory) {
      createProductTypeAsync(createdType);
    } else {
      if (type === "") {
        TOAST_ALERT("Please Enter Type Name!", "error");
      }
      if (selectedCategory === "") {
        TOAST_ALERT("Please Select Category!", "error");
      }
    }
  };

  useEffect(() => {
    displayAllCategories();
  }, []);

  const displayAllCategories = async () => {
    await getAllCategoriesList().then((categories => {
      setCategories(categories.data);
    }))
  }

  const handleChangeSelect = (event) => {
    setSelectedCategory(event.target.value.id);
  }

  const handleClick = () => {
    setIsDisabled(true);
  };

  return (
    <div style={{ backgroundColor: "white", boxShadow: "3px 3px 15px rgba(0, 0, 0, 0.07)" }}>
      <div style={{ padding: "15px" }}>
        <div style={{ width: "300px" }}>
          <Paper>
            <div style={{ padding: "20px" }}>
              <Input
                id="input__type"
                type="text"
                value={type}
                fullWidth
                onChange={(e) => setType(e.target.value)}
                placeholder="Enter product type name*"
                required
              />
              <FormControl fullWidth>
                <InputLabel
                  required
                >
                  Category
                </InputLabel>
                <Select
                  required
                  placeholder="Select category"
                  onChange={handleChangeSelect}
                >
                  {
                    categories.map(category => {
                      return (
                        <MenuItem
                          key={category.id} value={category} > {category.categoryName} </MenuItem>
                      )
                    })
                  }
                </Select>
              </FormControl>
            </div>
          </Paper>
        </div>


        <div style={{ paddingTop: "30px" }}>
          <CreateProductTypeForm
            editMode={editMode}
            handleSubmit={handleSubmit}
            handleChange={handleChange}
            newFieldType={newFieldType}
            productFieldNames={productFieldNames}
            setEditMode={setEditMode}
            setNewFieldType={setNewFieldType}
          />
        </div>
      </div>
      {dynamicFields.length ? (
        <div>
          <ProductTypeDynamicTable
            handleUpdateType={handleUpdateType}
            handleDeleteType={handleDeleteType}
            dynamicFields={dynamicFields}
            editMode={editMode}
            newFieldType={newFieldType}
          />
        </div>
      ) : null}
      {dynamicFields.length ? (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "20px" }}>
          <Button
            size="medium"
            color="primary"
            onClick={() => {
              props.history.push("/productTypes");
            }}
          >
            Go back
          </Button>
          <Button
            variant="text"
            disabled={isDisabled}
            size="medium"
            color="primary"
            startIcon={<AddIcon fontSize="small" />}
            onClick={() => {
              handleClick();
              createNewType();
            }}
          >
            <span>Create Product Type</span>
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export default CreateProductType;

