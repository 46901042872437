import React, { useContext, useState } from "react";
import { defaultStartingPage, defaultNumProductsPerPage } from "../config/config";
import { SearchContext } from "../context/SearchContextProvider";


const HOC = (OriginalComponent) => {
  const NewComponent = () => {
    //CONTEXT

    const {
      productsData,
      ordersData,
      productTypesData,
      setProductTypesData,
      getProductTypesData,
      searchQuery,
      searchProductTypeQuery,
      setOrdersData,
      setProductsData,
      totalPages,
      totalCount,
      loadData,
      getProductsData,
      getAllProductTypesData,
      getOrdersData,
      cancelSearchData,
      setDeleteId,
      homeTagSearch,
      setHomeTagSearch,
    } = useContext(SearchContext);

    //HOOKS
    const [page, setPage] = useState(defaultStartingPage);
    const [numProducts, setNumProducts] = useState(defaultNumProductsPerPage);
    const [numOrders, setNumOrders] = useState(defaultNumProductsPerPage);
    const [numProductTypes, setNumProductTypes] = useState(defaultNumProductsPerPage);
    const [search, setSearch] = useState("");
    const [multipleSearch, setMultipleSearch] = useState([]);
    const [cancelSearch, setCancelSearch] = useState(true);
    const [loadingIndicator, setLoadingIndicator] = useState(true);

    //HANDLERS
    const multipleSearchChange = (searchArray) => {
      setMultipleSearch(searchArray);
    };

    const searchChange = (searchValue) => {
      setSearch(searchValue);
    };

    const paginationChange = (selectedPage = page, PATH) => {
      setPage(selectedPage);
      getProductsData(PATH, selectedPage, numProducts);
    };

    const paginationOrderChange = (selectedPage = page, PATH) => {
      setPage(selectedPage);
      getOrdersData(PATH, selectedPage, numProducts, searchQuery);
    };

    const paginationProductTypeChange = (selectedPage = page, PATH) => {
      setPage(selectedPage);
      getAllProductTypesData(PATH, selectedPage, numProductTypes, searchProductTypeQuery);
    }

    const dropdownProductTypeChange = (selectedNumProductTypes, PATH) => {
      setNumProductTypes(selectedNumProductTypes);
      setPage(defaultStartingPage);
      getAllProductTypesData(PATH, defaultStartingPage, selectedNumProductTypes, searchProductTypeQuery);
    };


    const dropdownOrderChange = (selectedNumOrders, PATH) => {
      setNumOrders(selectedNumOrders);
      setPage(defaultStartingPage);
      getOrdersData(PATH, defaultStartingPage, selectedNumOrders, searchQuery);
    };

    const dropdownChange = (selectedNumProducts, PATH) => {
      setNumProducts(selectedNumProducts);
      setPage(defaultStartingPage);
      getProductsData(PATH, defaultStartingPage, selectedNumProducts);
    };

    const handleProductTypeSubmit = (PATH) => {
      if (search.trim() || multipleSearch.length) {
        setPage(defaultStartingPage);
        setCancelSearch(false);
        setLoadingIndicator(false);
        getProductTypesData(PATH, defaultStartingPage, numProductTypes);
      }
    };


    const handleOrderSubmit = (PATH) => {
      if (search.trim() || multipleSearch.length) {
        setPage(defaultStartingPage);
        setCancelSearch(false);
        setLoadingIndicator(false);
        getOrdersData(PATH, defaultStartingPage, numOrders);
      }
    };

    //SUBMIT TO CONTEXT
    const handleSubmit = (PATH) => {
      if (search.trim() || multipleSearch.length) {
        setPage(defaultStartingPage);
        setCancelSearch(false);
        setLoadingIndicator(false);
        getProductsData(PATH, defaultStartingPage, numProducts, search, multipleSearch);
      }
    };

    
    const handleCancel = (PATH) => {
      setPage(defaultStartingPage);
      setSearch("");
      setMultipleSearch([]);
      setHomeTagSearch([]);
      setLoadingIndicator(true);
      cancelSearchData(PATH, numProducts);
    };

    const values = {
      productsData,
      setProductsData,
      getProductsData,
      setOrdersData,
      ordersData,
      totalPages,
      totalCount,
      loadData,
      setDeleteId,
      page,
      numProducts,
      numOrders,
      numProductTypes,
      search,
      multipleSearch,
      cancelSearch,
      getOrdersData,
      setCancelSearch,
      setNumProductTypes,
      loadingIndicator,
      productTypesData,
      setProductTypesData,
      paginationProductTypeChange,
      dropdownProductTypeChange,
      handleProductTypeSubmit,
      setNumOrders,
      setLoadingIndicator,
      multipleSearchChange,
      paginationOrderChange,
      searchChange,
      paginationChange,
      handleOrderSubmit,
      dropdownChange,
      dropdownOrderChange,
      handleSubmit,
      handleCancel,
      homeTagSearch,
      setHomeTagSearch,
    };

    return <OriginalComponent {...values} />;
  };

  return NewComponent;
};

export default HOC;
