import axios from "axios";
import { URL } from "../../config/config";
import { extractToken } from "../../helpers/helpers";

const authAxios = axios.create({
  baseURL: URL,
  headers: {
    Authorization: `Bearer ${extractToken()}`,
  },
});

export default authAxios;
