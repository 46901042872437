import { TextField } from "@material-ui/core";
import React from "react";
import { proccessFieldType } from "../../../helpers/helpers";

const DatePicker = ({
  openField,
  fieldType,
  required,
  fieldName,
  id,
  fieldValue,
  openCreateField,
  handleChange,
}) => {
  return (
    <TextField
      label={fieldName}
      className="input__form"
      onChange={(e) => handleChange(e, id)}
      value={fieldValue}
      type={proccessFieldType(fieldType)}
      name={fieldName}
      required={required}
      disabled={openCreateField ? false : openField === id ? false : true}
      InputLabelProps={{ shrink: true }}
    />
  );
};

export default DatePicker;
