import {
    Button,
    Typography,
    Grid,
    InputLabel,
    Card,
    CardContent,
    Input,
    Select,
    MenuItem
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { createOrderItem } from "../../api/ordersApi/ordersApi";
import { getAllProductNames } from "../../api/productsApi/productsApi";


const CreateOrderItemForm = (props) => {

    const orderItemForCreating = {
        orderId: "",
        productName: "",
        price: "",
        quantity: "",
    }
    const { history } = props;
    const { orderId } = props.match.params;
    const [orderItemForCreate, setOrderItemForCreate] = useState(orderItemForCreating);
    const [selected, setSelected] = useState('');
    const [isDisabled, setIsDisabled] = useState(false);
    const [productNames, setProductNames] = useState([]);


    orderItemForCreate.orderId = orderId;
    const handleCreateOrderItem = async (event) => {
        event.preventDefault();
        try {
            await createOrderItem(orderItemForCreate);
            history.push(`/orders/orderItems/${orderId}`);
        }
        catch (error) {
            console.log(error);
        }
    }

    const handleProductNames = async () => {
        await getAllProductNames().then((res) => {
            setProductNames(res.data);
        });
    }

    useEffect(() => {
        handleProductNames();
    }, []);


    const handleChange = (event) => {
        const { name, value } = event.target;
        setOrderItemForCreate({ ...orderItemForCreate, [name]: value });
        if(name === "productName"){
            setSelected(value);
        }
    };

    const handleClick = () => {
        setIsDisabled(true);
      };
    

    return (
        <Grid container>
            <Card style={{ maxWidth: 600, margin: '0 auto', padding: '20px 20px' }}>
                <CardContent>
                    <Typography gutterBottom variant="h2" style={{ textAlign: 'center' }}>
                        Create Order Item
                    </Typography>
                    <form>
                        <Grid container>
                            <Grid xs={12} item style={{ paddingTop: '20px', paddingRight: '20px' }}>
                                <InputLabel required htmlFor="bootstrap-input" style={{ paddingBottom: '5px' }}>
                                    Product Name
                                </InputLabel>
                                <Select
                                    value={selected}
                                    required
                                    fullWidth
                                    variant="standard"
                                    name="productName"
                                    onChange={handleChange}
                                >
                                    {productNames.map((productName, index) => {
                                        return (
                                            <MenuItem key={index} value={productName}>{productName}</MenuItem>
                                        )
                                    })}

                                </Select>
                            </Grid>

                            <Grid xs={12} sm={6} item style={{ paddingTop: '50px', paddingRight: "20px" }}>
                                <InputLabel required htmlFor="bootstrap-input" style={{ paddingBottom: '25px' }}>
                                    Price
                                </InputLabel>
                                <Input
                                    variant="standard"
                                    type="number"
                                    required
                                    fullWidth
                                    name="price"
                                    onChange={handleChange}
                                />
                            </Grid>

                            <Grid xs={12} sm={6} item style={{ paddingTop: '50px' }}>
                                <InputLabel required htmlFor="bootstrap-input" style={{ paddingBottom: '25px' }}>
                                    Quantity
                                </InputLabel>
                                <Input
                                    variant="standard"
                                    type="number"
                                    required
                                    fullWidth
                                    name="quantity"
                                    onChange={handleChange}
                                />
                            </Grid>

                            <Grid xs={12} sm={6} item style={{ paddingTop: '80px' }}>
                                <Button
                                    type="submit"
                                    varient="outlined"
                                    color="primary"
                                    fullWidth
                                    disabled={isDisabled}
                                    onClick={(e) => {
                                        handleClick();
                                        handleCreateOrderItem(e);
                                    }}
                                >
                                    Submit
                                </Button>
                            </Grid>
                            <Grid xs={12} sm={6} item style={{ paddingTop: '80px' }}>
                                <Button
                                    type="button"
                                    varient="outlined"
                                    color="primary"
                                    fullWidth
                                    onClick={() => history.push(`/orders/orderItems/${orderId}`)}
                                >
                                    GO BACK
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </CardContent>
            </Card>
        </Grid>
    )
}

export default CreateOrderItemForm;
