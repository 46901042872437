import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import ProductTypeTable from "../../components/productTypeTable/ProductTypeTable";
import { Button, Input, InputLabel } from "@material-ui/core";
import Dropdown from "../../components/searchComponents/dropdown/Dropdown";
import Pagination from "../../components/searchComponents/pagination/Pagination";
import SearchHOC from "../../HOC/SearchHOC";
import { SearchContext } from "../../context/SearchContextProvider";
import {
  defaultNumProductsPerPage,
  defaultStartingPage,
  PRODUCT_TYPE,
} from "../../config/config";
import SearchFilterProductTypes from "../../components/searchComponents/searchFilterProductTypes/SearchFilterProductTypes";


const ProductTypes = ({
  page,
  totalPages,
  productTypesData,
  setProductTypesData,
  setNumProductTypes,
  numProductTypes,
  numProducts,
  numOrders,
  dropdownProductTypeChange,
  paginationProductTypeChange,
}) => {

  const cancelDefaultQueryModel = {
    name: "",
    categoryName: ""
  }

  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const { getProductTypesData, getAllProductTypesData,  searchProductTypeQuery, cancelSearchProductTypesData } = useContext(SearchContext);

  const getProducts = async () => {
    setLoadingIndicator(true);
    await getProductTypesData(
      PRODUCT_TYPE,
      defaultStartingPage,
      defaultNumProductsPerPage,
    ).then(() => {
      setLoadingIndicator(false);
    });
    return () => {
      setProductTypesData([]);
    }
  };

  useEffect(async () => {
    await getProducts();
  }, []);


  const handleSubmit = async () => {
    await getAllProductTypesData(
      PRODUCT_TYPE,
      defaultStartingPage,
      defaultNumProductsPerPage,
      searchProductTypeQuery
    ).then(() => {
      setLoadingIndicator(false);
    });
    return () => {
      setProductTypesData([]);
    }
  }

  const handleCancelQuery = (e) => {
    cancelSearchProductTypesData(
      PRODUCT_TYPE,
      defaultStartingPage,
      defaultNumProductsPerPage,
      cancelDefaultQueryModel
    );
    return () => {
      setProductTypesData([]);
    };
  }


  return (
    <div style={{ backgroundColor: "white", boxShadow: "3px 3px 15px rgba(0, 0, 0, 0.07)", width: "1200px", margin: "auto" }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <h2 style={{ padding: "20px" }}>Product types</h2>
          <span style={{ padding: "20px" }} >Currently added product types ({productTypesData.length})</span>
        </div>
        <SearchFilterProductTypes
          handleCancelQuery={handleCancelQuery}
          handleSubmit={handleSubmit}
          setNumProductTypes={setNumProductTypes}
          cancelDefaultQueryModel={cancelDefaultQueryModel}
          />
          
        <Button
          variant="text"
          size="large"
          color="primary"
          component={Link}
          to="/productTypes/create"
          id="create__product__button"
        >

          <span style={{ paddingRight: "10px", color: "#37A2DB" }}>+Create Product Type</span>
        </Button>
      </div>
      <div className="product-type-table">
        <ProductTypeTable
          loadingIndicator={loadingIndicator}
          getProducts={getProducts}
          productTypesData={productTypesData && productTypesData}
        />
      </div>
      <div className="product-page-results-paggination">
        <Dropdown
          numProducts={numProducts}
          numOrders={numOrders}
          numProductTypes={numProductTypes}
          dropdownProductTypeChange={dropdownProductTypeChange}
          path={PRODUCT_TYPE}
        />
        <Pagination
          page={page}
          paginationProductTypeChange={paginationProductTypeChange}
          totalPages={totalPages}
          path={PRODUCT_TYPE}
        />
      </div>
    </div>
  );
};

export default SearchHOC(ProductTypes);
