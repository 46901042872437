import { React, useState, useEffect, useContext } from "react";
import { Button } from "@material-ui/core";
import { Link } from 'react-router-dom';
import { AuthContext } from "../../context/AuthProvider";
import Dropdown from "../../components/searchComponents/dropdown/Dropdown";
import Pagination from "../../components/searchComponents/pagination/Pagination";
import SearchHOC from "../../HOC/SearchHOC";
import {
  defaultNumProductsPerPage,
  defaultStartingPage,
  GET_ORDERS,
} from "../../config/config";
import OrdersTable from "../../components/ordersTable/OrdersTable";
import SearchByOrderModal from "../../components/searchComponents/searchFilterOrder/SearchFilterOrder";
import { SearchContext } from "../../context/SearchContextProvider";


const OrdersPage = ({
  ordersData,
  setLoadingIndicator,
  setOrdersData,
  getOrdersData,
  totalPages,
  setDeleteId,
  setNumOrders,
  page,
  numProducts,
  numOrders,
  paginationOrderChange,
  loadingIndicator,
  paginationChange,
  dropdownOrderChange,
}) => {

  const cancelDefaultQueryModel = {
    deliveryAdress: "",
    createdDateBefore: "",
    createdDateAfter: "",
    deliveredDateBefore: "",
    deliveredDateAfter: "",
    supplier: "",
    totalAmountLessOrEqual: "",
    totalAmountGreatOrEqual: ""
  };
  
  const { userRole } = useContext(AuthContext);

  const {searchQuery, cancelSearchOrderData} = useContext(SearchContext);

  const handleQuerySearch = () => {
    getOrdersData(
      GET_ORDERS,
      defaultStartingPage,
      defaultNumProductsPerPage,
      searchQuery
    );
    return () => {
      setOrdersData([]);
    };
  }

  const handleCancelQuery = () => {
    cancelSearchOrderData(
      GET_ORDERS,
      defaultStartingPage,
      defaultNumProductsPerPage,
      cancelDefaultQueryModel
    );
    return () => {
      setOrdersData([]);
    };
  }

  useEffect(() => {
    handleQuerySearch();
  }, []);

  const columns = [
    { id: "deliveryAddress", label: "Delivery Address", minWidth: 200 },
    {
      id: "CreatedOrderDate",
      label: "Created Order Date",
      align: "left",
    },
    {
      id: "DeliveryOrderDate",
      label: "Delivery Order Date",
      align: "left",
    },
    {
      id: "Suplier",
      label: "Suplier",
      align: "left",
    },
    {
      id: "Order Status",
      label: "Order Status",
      align: "center",
    },
    {
      id: "Note",
      label: "Note",
      align: "center",
    },
    {
      id: "OrderItems",
      label: "Order Items",
      align: "center",
    },
    {
      id: "Currency",
      label: "Currency",
      align: "right",
    },
    {
      id: "Actions",
      label: "Actions",
      align: "center",
    },
  ];

  return (
    <div className="product-page">
      <div className="product-page-dropdown-create">
        <div>
          <h2>Orders</h2>
          <span>Currently {ordersData.length} orders added</span>
        </div>
        <div className="product-page-data-info">
        </div>
        <div className="product-page-search">
          <SearchByOrderModal handleCancelQuery={handleCancelQuery} setNumOrders={setNumOrders} handleQuerySearch={handleQuerySearch} cancelDefaultQueryModel={cancelDefaultQueryModel}/>
        </div>
        <div className="product-page-add-btn">
          {userRole === "Admin" ? (
            <Button
              id="create__product__button"
              variant="text"
              size="large"
              component={Link}
              to="/orders/create"
            >
              <span className="add-new-product-span">+Add new order</span>
            </Button>
          ) : null}
        </div>
      </div>
      <OrdersTable
        paginationChange={paginationChange}
        paginationOrderChange={paginationOrderChange}
        columns={columns}
        ordersData={ordersData}
        setLoadingIndicator={setLoadingIndicator}
        loadingIndicator={loadingIndicator}
        setDeleteId={setDeleteId}
        path={GET_ORDERS}
      />

      <div className="product-page-results-paggination">
        <Dropdown
          numProducts={numProducts}
          numOrders={numOrders}
          dropdownOrderChange={dropdownOrderChange}
          path={GET_ORDERS}
        />
        <Pagination
          page={page}
          paginationOrderChange={paginationOrderChange}
          totalPages={totalPages}
          path={GET_ORDERS}
        />
      </div>
    </div>
  );
};

export default SearchHOC(OrdersPage);

