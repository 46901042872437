import React from "react";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { proccessFieldType } from "../../helpers/helpers";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";


const ProductTypeDynamicTable = ({
  handleUpdateType,
  handleDeleteType,
  dynamicFields,
  editMode,
  newFieldType,
}) => {
  return (
    <Paper id="createProductType" className="table__responsive">
      <TableContainer style={{ padding: "40px"}}>
        <Table aria-label="a dense table">
          <TableHead>
            <TableRow className="table__head">
              <TableCell
                className="table__head__cell createProductType__cell"
                align="left"
              >
                Field Name
              </TableCell>
              <TableCell
                className="table__head__cell createProductType__cell"
                align="left"
              >
                Field Type
              </TableCell>
              <TableCell
                className="table__head__cell createProductType__cell"
                align="left"
              >
                Required
              </TableCell>
              <TableCell
                className="table__head__cell createProductType__cell"
                align="left"
              >
                Searchable
              </TableCell>
              <TableCell
                className="table__head__cell createProductType__cell"
                align="left"
              >
                Unique
              </TableCell>
  
              <TableCell
                className="table__head__cell createProductType__cell"
                align="center"
              >
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dynamicFields &&
              dynamicFields.map(
                (
                  {
                    fieldName,
                    fieldType,
                    isRequired,
                    unique,
                    searchable,
                    id,
                  },
                  i
                ) => (
                  <TableRow id="newProductProps" key={id} hover tabIndex={-1}>
                    <TableCell className="createProductType__cell" align="left">
                      {fieldName}
                    </TableCell>
                    <TableCell className="createProductType__cell" align="left">
                      {proccessFieldType(fieldType)}
                    </TableCell>
                    <TableCell className="createProductType__cell" align="left">
                      {isRequired ? "true" : "false"}
                    </TableCell>
                    <TableCell className="createProductType__cell" align="left">
                      {searchable ? "true" : "false"}
                    </TableCell>
                    <TableCell className="createProductType__cell" align="left">
                      {unique ? "true" : "false"}
                    </TableCell>
                    <TableCell className="createProductType__cell" align="right">
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Box ml={1}>
                          <Button
                            className="button__edit"
                            size="large"
                            color="primary"
                            startIcon={<EditOutlinedIcon className="edit-icon" />}
                            onClick={() => handleUpdateType(id)}
                          >
                          </Button>
                        </Box>
                        <Box ml={1}>
                          <Button
                            disabled={fieldName === "Name" ? true : false}
                            color="secondary"
                            size="large"
                            className="button__delete"
                            startIcon={<DeleteOutlineIcon className="delete-icon" />}
                            onClick={() => handleDeleteType(id)}
                            disabled={
                              (editMode && newFieldType.id === id) ||
                              fieldName === "Name"
                            }
                          >
                          </Button>
                        </Box>
                      </Box>
                    </TableCell>
                  </TableRow>
                )
              )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default ProductTypeDynamicTable;
