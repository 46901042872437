import authAxios from "../authAxios/authAxios";
import { PRODUCT_TYPE, TYPE_DEFINITION, URL } from "../../config/config";
import { extractToken } from "../../helpers/helpers";
import { TOAST_ALERT } from "../../static/toastify/toastify";

// Create Product Type
export const createProductType = async (productType) => {
  let res;
  try {
    res = await authAxios.post(`/${PRODUCT_TYPE}`, productType);
    TOAST_ALERT("Product Type Created", "success");
  } catch (err) {
    const [error] = err.response.data.errors.ProductTypeName;
    TOAST_ALERT(`${error}`, "error");
    throw err;
  }
  return res;
};

// Update Product Type
export const updateProductTypeApi = async (product) => {
  try {
    await authAxios.put(`/${PRODUCT_TYPE}`, product);
    TOAST_ALERT("Product Type Updated", "success");
  } catch (err) {
    console.log("app")
    TOAST_ALERT("Error occured", "error");
  }
};

// Delete Product Type
export const deleteProductType = async (id) => {
  try {
    const res = await authAxios.delete(`/${PRODUCT_TYPE}/${id}`);
    if (res.data) {
      TOAST_ALERT("Product Type Deleted", "success");
    } else {
      TOAST_ALERT("Products are already created with this Product Type.", "error");
    }
  } catch (err) {
    console.log("app")
    TOAST_ALERT("Error occured", "error");
  }
};
//  Get Product Type
export const getProductTypeData = async (productName) => {
  let res;
  try {
    res = await authAxios.get(`/${PRODUCT_TYPE}/${productName}`);
  } catch (err) {
    console.log("app")
    TOAST_ALERT("Error occured", "error");
  }
  return res;
};

// Get All Product Types
export const getAllProductTypes = async (path, page, size, searchQuery) => {

  const { name, categoryName } = searchQuery;
  let res;
  try {
    res = await authAxios.get(`${URL}/${path}`, {
      params: {
        pageNumber: page,
        pageSize: size,
        name: name,
        categoryName: categoryName
      }
    },
      {
        headers: {
          Authorization: `Bearer ${extractToken()}`,
        },
      }
    );
  } catch (err) {
    console.log("app")
    TOAST_ALERT("Error occured", "error");
    throw err;
  }
  return res;
};
// Get All Product Types without pagination
export const getAllProductTypesWithoutPagination = async () => {
  let res;
  try {
    res = await authAxios.get(`${URL}/${PRODUCT_TYPE}/getAllProductTypes`);
  } catch (err) {
    console.log("app")
    TOAST_ALERT("Error occured", "error");
    throw err;
  }
  return res;
};

// Create Product type definiton
export const createProductTypeDefinition = async (newTypeDefinition) => {
  await authAxios
    .post(`/${TYPE_DEFINITION}`, newTypeDefinition)
    .then((res) => {
      console.log("asd");
      TOAST_ALERT("Field Created", "success");
    })
    .catch((err) => {
      let errors = err.response.data.errors;
      let value = errors[""];
      value.map((el) => TOAST_ALERT(el, "error"));
    });
};

// Update Product type definiton
export const updateProductTypeDefinition = async (newTypeDefinition) => {
  let res;
  try {
    res = await authAxios.put(`/${TYPE_DEFINITION}`, newTypeDefinition);
    TOAST_ALERT("Field Updated", "success");
  } catch (err) {
    console.log("app")
    TOAST_ALERT("Error occured", "error");
    throw err;
  }
  return res;
};
// Delete Product type definiton
export const deleteProductTypeDefinition = async (typeId, definitonId) => {
  let res;
  try {
    res = await authAxios.delete(
      `/${TYPE_DEFINITION}/${typeId}/${definitonId}`
    );
    TOAST_ALERT("Field Deleted", "success");
  } catch (err) {
    TOAST_ALERT(
      "Products are already created with this Field Definition.",
      "error"
    );
  }
};
