import React, { useState, useEffect } from "react";
import { productFieldNames } from "../../static/db/db";
import { Button, Input, Paper, InputLabel, Select, MenuItem } from "@material-ui/core";
import {
  createProductTypeDefinition,
  getProductTypeData,
  updateProductTypeDefinition,
  deleteProductTypeDefinition,
  updateProductTypeApi,
} from "../../api/productTypeApi/productTypeApi";
import ProductTypeDynamicTable from "../../components/productTypeDynamicTable/ProductTypeDynamicTable";
import EditProductTypeForm from "../../components/productTypeForm/EditProductTypeForm";
import { getAllCategoriesList } from "../../api/categoryApi/categoryApi";

const EditProductType = (props) => {
  const query = props.match.params.productTypeId;
  const [type, setType] = useState("");
  const [productTypeId, setProductTypeId] = useState();
  const [dynamicFields, setDinamicFields] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [categories, setCategories] = useState([]);
  const [categoryName, setCategoryName] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [newFieldType, setNewFieldType] = useState({
    fieldName: "",
    fieldType: 1,
    isRequired: false,
    searchable: false,
    unique: false,
  });

  const productTypeForUpdate = {
    id: "",
    productTypeName: "",
    categoryId: ""
  }
  const updateDynamicFields = async () => {
    await getProductTypeData(query).then((res) => {
      setDinamicFields(res.data.fieldDefinitions);
    });
  };

  useEffect(async () => {
    await getAllCategoriesList().then((res) => {
      setCategories(res.data);
    });
  }, []);


  useEffect(async () => {
    await getProductTypeData(query).then((res) => {
      setCategoryName(res.data.categoryName);
      setProductTypeId(res.data.id);
      setType(res.data.productTypeName);
      setDinamicFields(res.data.fieldDefinitions);
    });
  }, []);

  const handleChange = (e) => {
    const target = e.target;
    let value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    if (name === "fieldType") {
      value = Number(value);
    }
    setNewFieldType({
      ...newFieldType,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newFieldDefinition = {
      ...newFieldType,
      productTypeId,
    };
    if (editMode) {
      await updateProductTypeDefinition(newFieldDefinition);
      await updateDynamicFields();
    } else {
      await createProductTypeDefinition(newFieldDefinition);
      await updateDynamicFields();
    }
    setEditMode(false);
    setNewFieldType({
      fieldName: "",
      fieldType: 1,
      isRequired: false,
      searchable: false,
      unique: false,
    });
  };
  const handleDeleteType = async (id) => {
    await deleteProductTypeDefinition(productTypeId, id);
    await updateDynamicFields();
  };

  const handleUpdateType = (id) => {
    const editedType = dynamicFields.find((type) => type.id === id);
    setNewFieldType(editedType);
    setEditMode(true);
  };

  const updateProductType = async () => {
    productTypeForUpdate.id = productTypeId;
    productTypeForUpdate.productTypeName = type;
    productTypeForUpdate.categoryId = categoryId;
    await updateProductTypeApi(productTypeForUpdate);

    props.history.push("/productTypes");
  };

  const handleChangeSelect = (event) => {
    const { name, value } = event.target;
    if (name === "categoryId") {
      setCategoryName(value);
    }
  }

  const handleClick = () => {
    setIsDisabled(true);
  };

  return (
    <div style={{ backgroundColor: "white", boxShadow: "3px 3px 15px rgba(0, 0, 0, 0.07)" }}>
      <div style={{ padding: "15px" }}>
        <div style={{ width: "300px"}}>
          <Paper>
            <div style={{ padding: "20px"}}>
              <Input
                id="input__type"
                type="text"
                fullWidth
                value={type}
                onChange={(e) => setType(e.target.value)}
                placeholder="Enter Product type name"
                required
                disabled={true}
              />
              <InputLabel style={{ paddingTop: "20px" }}>
                Category
              </InputLabel>
              <Select
                style={{ paddingTop: "10px"}}
                fullWidth
                value={categoryName}
                name="categoryId"
                onChange={handleChangeSelect}
              >
                {
                  categories.map(category => {
                    return (
                      <MenuItem onClick={() => {
                        setCategoryId(category.id);
                      }} key={category.id} value={category.categoryName} >{category.categoryName}</MenuItem>
                    )
                  })
                }
              </Select>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingTop: "30px" }}>
                <Button
                  size="medium"
                  color="primary"
                  onClick={() => {
                    props.history.push("/productTypes");
                  }}
                >
                  Go back
                </Button>
                <Button
                  disabled={isDisabled}
                  size="medium"
                  color="primary"
                  onClick={() => {
                    handleClick();
                    updateProductType();
                  }}
                >
                  Update
                </Button>
              </div>
            </div>
          </Paper>
        </div>
        <div style={{ paddingTop: "30px"}}>
        <EditProductTypeForm
            editMode={editMode}
            handleSubmit={handleSubmit}
            handleChange={handleChange}
            newFieldType={newFieldType}
            productFieldNames={productFieldNames}
            setEditMode={setEditMode}
            setNewFieldType={setNewFieldType}
          />
        </div>
      </div>
      {dynamicFields.length ? (
        <div>
          <ProductTypeDynamicTable
            handleUpdateType={handleUpdateType}
            handleDeleteType={handleDeleteType}
            dynamicFields={dynamicFields}
            editMode={editMode}
            newFieldType={newFieldType}
          />
        </div>
      ) : null}
    </div>
  );
};

export default EditProductType;
