import React, { useState } from "react";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from '@material-ui/core/IconButton';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Input } from "@material-ui/core";

const SingleSearch = ({ searchChange, searchValue, labelSearch, handleSubmit, path, handleCancel, setCancelSearch, cancelSearch }) => {

  const [isSearchButtonClicked, setSearchButton] = useState(false);

  const handleSubmitForm = (e) => {
    e.preventDefault();
    handleSubmit(path);
  };

  return (
    <form onSubmit={handleSubmitForm} className="search">
      <Input
        id="outlined-adornment-Search"
        label={`Search ${labelSearch}`}
        placeholder="Search"
        value={searchValue}
        className="input-styles"
        onChange={(e) => {
          { searchChange(e.target.value) }
        }}
        endAdornment={
          <InputAdornment position="end">
            {isSearchButtonClicked === false ? (<IconButton
              aria-label="Search"
              edge="end"
              type="submit"
              onClick={() => {
                setSearchButton(true);
              }}
            >
              <FontAwesomeIcon className="fa-regular fa-magnifying-glass" size="sm" icon={faSearch} />
            </IconButton>
            )
            : (
            <IconButton
              aria-label="Cancel"
              edge="end"
              type="submit"
              onClick={() => {
                setSearchButton(false);
                handleCancel(path);
                setCancelSearch(!cancelSearch);
              }}
            >
              <FontAwesomeIcon className="far fa-times" icon={faTimes} />
            </IconButton>
            )}
          </InputAdornment>
        }
      />
    </form>
  );
};

export default SingleSearch;
