import React, { createContext, useState } from "react";
import { loginRequest } from "../authConfig";
import { useMsal } from "@azure/msal-react";
import { getAllOrdersList, deleteOrderItemById, getSingleOrder } from "../api/ordersApi/ordersApi";


export const OrdersFormProvider = createContext({});

export const OrderContextProvider = (props) => {
  const { instance } = useMsal();
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState({});
  const [orderItems, setOrderItems] = useState([]);

  const getOrders = async () => {
    await getAllOrdersList()
      .then(({ data }) => {
        setOrders(data);
      })
      .catch((err) => {
        const message = err.message.split(" ");
        if (message[message.length - 1] === "401") {
          instance.logout(loginRequest);
        }
      });
  };

  const handleCurrency = (currency) => {
    if (currency === 1) {
      return <h3>MKD</h3>
    }
    if (currency === 2) {
      return <h3>SEK</h3>
    }
    if (currency === 3) {
      return <h3>EUR</h3>
    }
  };


  const handleDeleteOrderItemById = async (order, orderItemId) => {
    await deleteOrderItemById(order, orderItemId)
      .then((res) => {

      })
      .catch((err) => {
        const message = err.message.split(" ");
        if (message[message.length - 1] === "401") {
          instance.logout(loginRequest);
        }
      });
  };

  const fetchOrder = async (orderId) => {
    await getSingleOrder(orderId).then((res) => {
        setOrder(res.data);
        setOrderItems(res.data.orderItems);
    });
}

  const values = {
    fetchOrder,
    setOrderItems,
    handleCurrency,
    handleDeleteOrderItemById,
    getOrders,
    setOrders,
    orderItems,
    order,
    orders,
    loading,
  };
  return (
    <OrdersFormProvider.Provider value={values}>
      {props.children}
    </OrdersFormProvider.Provider>
  );
};

export default OrderContextProvider;