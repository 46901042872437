import React from "react";
import { TextField } from "@material-ui/core";
import { proccessFieldType } from "../../../helpers/helpers";

const Input = ({
  openField,
  fieldType,
  required,
  fieldName,
  id,
  fieldValue,
  openCreateField,
  handleChange,
}) => {
  return (
    <TextField
      disabled={openCreateField ? false : openField === id ? false : true}
      className="input__form test__input"
      label={fieldName}
      onChange={(e) => handleChange(e, id)}
      value={fieldValue && fieldValue}
      type={proccessFieldType(fieldType)}
      name={fieldName}
      required={required}
    />
  );
};

export default Input;
