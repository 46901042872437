import React, { useState, useEffect } from "react";
import axios from "axios";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { URL } from "../../config/config";
import { extractToken } from "../../helpers/helpers";
import EditProductForm from "../../components/editProductForm/EditProductForm";
import { CircularProgress } from "@material-ui/core";

const EditProduct = (props) => {
  const { instance } = useMsal();
  const [editedProduct, setEditedProduct] = useState();
  const [loading, setLoading] = useState(true);
  const { categoryId } = props.match.params;

  const fetchEditedProduct = async () => {
    await axios
      .get(`${URL}/products/${encodeURIComponent(categoryId)}`, {
        headers: {
          Authorization: `Bearer ${extractToken()}`,
        },
      })
      .then((res) => {
        setEditedProduct(res.data);
        setLoading(false);
      })
      .catch((err) => {
        const message = err.message.split(" ");
        if (message[message.length - 1] === "401") {
          instance.logout(loginRequest);
        }
      });
  };

  useEffect(() => {
    fetchEditedProduct();
  }, []);

  

  return (
    <div className="layout__form">
      {loading ? (
        <div style={{ textAlign: "center" }}>
          <CircularProgress />
        </div>
      ) : (
        <EditProductForm
          categoryId={categoryId}
          fetchEditedProduct={fetchEditedProduct}
          {...props}
          loading={loading}
          editedProduct={editedProduct && editedProduct}
        />
      )}
    </div>
  );
};

export default EditProduct;
