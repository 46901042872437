import { Button, Grid, InputLabel, TextField } from '@material-ui/core';
import React from 'react';
import { useState } from 'react';
import { useContext } from 'react';
import { defaultNumProductsPerPage } from '../../../config/config';
import { SearchContext } from '../../../context/SearchContextProvider';


const SearchByOrderModal = ({ handleQuerySearch, handleCancelQuery, setNumOrders, cancelDefaultQueryModel }) => {

    const { searchQuery, setSearchQuery } = useContext(SearchContext);
    const [isSearchClicked, setIsSearchClicked] = useState(true);

    const {
        deliveryAdress,
        createdDateBefore,
        createdDateAfter,
        deliveredDateBefore,
        deliveredDateAfter,
        supplier,
        totalAmountLessOrEqual,
        totalAmountGreatOrEqual } = searchQuery;

    const handleSearch = (event) => {
        const { value, name } = event.target;
        setSearchQuery({ ...searchQuery, [name]: value });
    };

    return (
        <form style={{ width: "980px" }}>
            <Grid container spacing={2}>
                <Grid item xs={2}>
                    <InputLabel htmlFor="bootstrap-input" style={{ paddingBottom: "10px" }}>
                        DeliveryAdress
                    </InputLabel>
                    <TextField
                        value={deliveryAdress}
                        variant="standard"
                        type="text"
                        name="deliveryAdress"
                        onChange={handleSearch}
                    />
                </Grid>

                <Grid item xs={2}>
                    <InputLabel htmlFor="bootstrap-input" style={{ paddingBottom: "10px" }}>
                        CreatedDateBefore
                    </InputLabel>
                    <TextField
                        value={createdDateBefore}
                        variant="standard"
                        type="date"
                        name="createdDateBefore"
                        onChange={handleSearch}
                    />
                </Grid>

                <Grid item xs={2}>
                    <InputLabel htmlFor="bootstrap-input" style={{ paddingBottom: "10px" }}>
                        CreatedDateAfter
                    </InputLabel>
                    <TextField
                        value={createdDateAfter}
                        variant="standard"
                        type="date"
                        name="createdDateAfter"
                        onChange={handleSearch}
                    />
                </Grid>

                <Grid item xs={2}>
                    <InputLabel htmlFor="bootstrap-input" style={{ paddingBottom: "10px" }}>
                        DeliveredDateBefore
                    </InputLabel>
                    <TextField
                        value={deliveredDateBefore}
                        variant="standard"
                        type="date"
                        name="deliveredDateBefore"
                        onChange={handleSearch}
                    />
                </Grid>

                <Grid item xs={4}>
                    <InputLabel htmlFor="bootstrap-input" style={{ paddingBottom: "10px" }}>
                        DeliveredDateAfter
                    </InputLabel>
                    <TextField
                        value={deliveredDateAfter}
                        variant="standard"
                        type="date"
                        name="deliveredDateAfter"
                        onChange={handleSearch}
                    />
                </Grid>

                <Grid item xs={2}>
                    <InputLabel htmlFor="bootstrap-input" style={{ paddingBottom: "10px" }}>
                        Suplier
                    </InputLabel>
                    <TextField
                        value={supplier}
                        variant="standard"
                        type="text"
                        name="supplier"
                        onChange={handleSearch}
                    />
                </Grid>

                <Grid item xs={3}>
                    <InputLabel htmlFor="bootstrap-input" style={{ paddingBottom: "10px" }}>
                        TotalAmountLessOrEqual
                    </InputLabel>
                    <TextField
                        value={totalAmountLessOrEqual}
                        variant="standard"
                        type="text"
                        name="totalAmountLessOrEqual"
                        onChange={handleSearch}
                    />
                </Grid>

                <Grid item xs={3}>
                    <InputLabel htmlFor="bootstrap-input" style={{ paddingBottom: "10px" }}>
                        TotalAmountGreatOrEqual
                    </InputLabel>
                    <TextField
                        value={totalAmountGreatOrEqual}
                        variant="standard"
                        type="text"
                        name="totalAmountGreatOrEqual"
                        onChange={handleSearch}
                    />
                </Grid>

                <Grid xs={3} item style={{ textAlign: 'center', paddingTop: "30px", paddingRight: "70px" }}>

                    {isSearchClicked === true
                        ?
                        <Button
                            onClick={(e) => {
                                setIsSearchClicked(false);
                                handleQuerySearch(e);
                            }}
                            size="large"
                            color="primary"
                            variant="text"
                        >
                            <span className="category-form-btn-color">+Search</span>
                        </Button>
                        :
                        <Button
                            onClick={(e) => {
                                setIsSearchClicked(true);
                                setSearchQuery(cancelDefaultQueryModel);
                                handleCancelQuery(e);
                                setNumOrders(defaultNumProductsPerPage);
                            }}
                            size="medium"
                            color="secondary"
                            variant="outlined"
                        >
                            <span>Cancel</span>
                        </Button>
                    }
                </Grid>
            </Grid>
        </form>
    );
}

export default SearchByOrderModal;