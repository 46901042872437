import React from "react";
import SingleSearch from "./SingleSearch";

const SearchComponent = ({
  searchChange,
  search,
  handleSubmit,
  handleCancel,
  cancelSearch,
  setCancelSearch,
  labelSearch,
  path,
}) => {


  return (
    <div>
      <SingleSearch
        searchChange={searchChange}
        searchValue={search}
        labelSearch={labelSearch}
        handleSubmit={handleSubmit}
        path={path}
        handleCancel={handleCancel}
        setCancelSearch={setCancelSearch}
        cancelSearch={cancelSearch}
      />
    </div>
  );
};

export default SearchComponent;
