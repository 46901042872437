import React, { useState } from "react";
import { deleteProductById } from "../../../api/productsApi/productsApi";
import { useMsal } from "@azure/msal-react";
import { GET_PRODUCTS, defaultStartingPage } from "../../../config/config";
import { loginRequest } from "../../../authConfig";
import { Button, Box, Paper } from "@material-ui/core/";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";
import { getItemByProductId } from "../../../api/itemsApi/itemsApi";
import { TOAST_ALERT } from "../../../static/toastify/toastify";
import { useEffect } from "react";


const DeleteModalDetails = ({
  deleteId,
  setIsOpenDelete,
  paginationChange,
}) => {

  const [itemNumber, setItemNumber] = useState(0);
  const { id } = deleteId;

  useEffect(() => {
    checkIfItemsExists(id);
  });

  const { instance } = useMsal();
  const handleDelete = async () => {
    if (itemNumber > 0) {
      TOAST_ALERT("Cannot delete items exists", "error");
    }
    else {
      await deleteProductById(deleteId)
        .then((res) => {
          paginationChange(defaultStartingPage, GET_PRODUCTS);
          setIsOpenDelete(false);
        })
        .catch((err) => {
          const message = err.message.split(" ");
          if (message[message.length - 1] === "401") {
            instance.logout(loginRequest);
          }
        });
    }
  };

  const checkIfItemsExists = async (itemId) => {
    await getItemByProductId(itemId)
      .then((res) => {
        setItemNumber(res.data.length);
      })
  }

  return (
    <Paper>
      <div className="modal">
        <div className="modal__header">
          <h2>Are you sure?</h2>
        </div>
        <div className="modal__btn__group">
          <div className="modal__btn__1">
            <Box ml={1}>
              <Button
                size="small"
                color="primary"
                startIcon={<CloseIcon fontSize="small" className="category-form-btn-color" />}
                onClick={() => setIsOpenDelete(false)}
              >
                <span className="category-form-btn-color">Cancel</span>
              </Button>
            </Box>
          </div>
          <div className="modal__btn__2">
            <Box ml={1}>
              <Button
                onClick={handleDelete}
                color="secondary"
                size="small"
                className="button__delete"
                startIcon={<DeleteIcon fontSize="small" />}
              >
                Delete
              </Button>
            </Box>
          </div>
        </div>
      </div>
    </Paper>
  );
};

export default DeleteModalDetails;
