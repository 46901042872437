import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { FormProvider } from "../../context/FormProvider";
import {
  Box,
  Button,
  Select,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import Element from "../form/elements/Element";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 3),
    boxShadow: "rgba(0, 0, 0, 0.27) 0px 10px 15px",
  },
  input: {
    marginTop: theme.spacing(1),
  },
  button: {
    marginLeft: "10px",
  },
}));

const CreateProductForm = (props) => {
  const classes = useStyles();
  const {
    handleSubmit,
    dynamicFields,
    handleType,
    type,
    productTypes,
    handleChange,
    handleImage,
  } = useContext(FormProvider);

  const [isDisabled, setIsDisabled] = useState(false);

  const handleCreateProduct = async (e) => {
    e.preventDefault();
    await handleSubmit();
    props.history.push("/products");
  };

  const handleClick = () => {
    setIsDisabled(true);
};


  return (
    <Paper className={classes.root}>
      <Box mb={5}>
        <Typography color="primary" className="create-product-color" variant="h5" component="h2">
          <span className="create-product-color">Create product</span>
        </Typography>
      </Box>

      <form
        style={{ display: "flex", flexDirection: "column" }}
      >
        <Select native value={type} onChange={(e) => handleType(e)}>
          {productTypes &&
            productTypes.map(({ id, productTypeName }) => {
              return (
                <option value={productTypeName} key={id}>
                  {productTypeName}
                </option>
              );
            })}
        </Select>

        {dynamicFields &&
          dynamicFields.map((elements) => {
            return (
              <Element
                handleImage={handleImage}
                notEditing={true}
                handleChange={handleChange}
                openCreateField={true}
                key={elements.id}
                elements={elements}
              />
            );
          })}

        <div className="create__product__buttons">
          <Box>
            <Button
              component={Link}
              to="/products"
              color="secondary"
            >
              Cancel
            </Button>
          </Box>
          <Box ml={1}>
            <Button
              className={classes.button}
              type="submit"
              disabled={isDisabled}
              onClick={(e) => {
                handleClick();
                handleCreateProduct(e)
              }}
              startIcon={<AddIcon fontSize="small" className="create-product-color" />}
            >
              <span className="create-product-color">Create product</span>
            </Button>
          </Box>
        </div>
      </form>
    </Paper>
  );
};

export default CreateProductForm;
