import React from "react";
import { TextField } from "@material-ui/core";

const TextFieldInput = ({
  openCreateField,
  openField,
  fieldName,
  id,
  fieldValue,
  handleChange,
}) => {
  return (
    <TextField
      className="input__form"
      disabled={openCreateField ? false : openField === id ? false : true}
      type={"textarea"}
      value={fieldValue}
      rows="4"
      size="medium"
      variant="outlined"
      onChange={(e) => handleChange(e, id)}
      placeholder={fieldName}
    />
  );
};

export default TextFieldInput;
