import axios from "axios";
import { URL } from "../../config/config";
import { extractToken } from "../../helpers/helpers";
import { TOAST_ALERT } from "../../static/toastify/toastify";

export const getTagsData = async () => {
  let response;
  try {
    response = await axios.get(`${URL}/Tags`, {
      headers: {
        Authorization: `Bearer ${extractToken()}`,
      },
    });
  } catch (error) {
    TOAST_ALERT("Error occured", "error");
    throw error;
  }
  return response;
};
export const getPopularTags = async () => {
  let response;
  try {
    response = await axios.get(`${URL}/Tags/popularTags`, {
      headers: {
        Authorization: `Bearer ${extractToken()}`,
      },
    });
  } catch (error) {
    TOAST_ALERT("Error occured", "error");
    throw error;
  }
  return response;
};
