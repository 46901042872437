import React, { useState } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Link } from 'react-router-dom';
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DetailsModal from "../modal/DetailsModal/DetailsModal";
import DeleteCategoryModal from "../modal/DeleteCategoryModal/DeleteCategoryModal.jsx";


const CategoryTable = ({ categories, setCategories, categoryLength, setIsToggled, isToggled }) => {
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [categoryArrIndex, setCategoryArrIndex] = useState(0);

  return (
    <div style={{ backgroundColor: "white", boxShadow: "3px 3px 15px rgba(0, 0, 0, 0.07)", width: "900px", margin: "auto" }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <h2 style={{ padding: "20px" }}>Categories</h2>
          <span style={{ padding: "20px"}}>Currently added categories ({categories.length})</span>
        </div>
        {categoryLength && <Button
          onClick={() =>
            setIsToggled(!isToggled)}
          color="primary"
          component={Link}
          to="/categories/create"
          size="large"
          variant="text"
        >
          <span style={{ paddingRight: "10px", color: "#37A2DB"}}>+Add new category</span>
        </Button>}
      </div>
      <TableContainer style={{ width: "900px", padding: "40px" }}>
        <Table>
          <TableHead>
            <TableRow className="cell-headers">
              <TableCell className="cell-headers">Name</TableCell>
              <TableCell className="cell-headers" align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {categories.map((category, index) => (
              <TableRow
                key={category.categoryName}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {category.categoryName}
                </TableCell>
                <TableCell align="right" >
                  <Button
                    onClick={() => {
                      setIsOpenDelete(true);
                      setDeleteId(
                        category.id
                      );
                      setCategoryArrIndex(index);
                    }}
                  ><DeleteOutlineIcon className="delete-icon" />
                  </Button>
                  <Button
                    className="button__edit"
                    startIcon={<EditOutlinedIcon className="edit-icon" />}
                    component={Link}
                    to={`/categories/update/${category.id}`}
                  >
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <DetailsModal open={isOpenDelete} onClose={() => setIsOpenDelete(false)}>
        <DeleteCategoryModal
          setCategories={setCategories}
          categories={categories}
          index={categoryArrIndex}
          deleteId={deleteId}
          setIsOpenDelete={setIsOpenDelete}
          onClose={() => setIsOpenDelete(false)} />
      </DetailsModal>
    </div>
  );
}

export default CategoryTable;