import React from "react";
import { Button, Checkbox, Input, Paper, Select } from "@material-ui/core";
import { proccessFieldType } from "../../helpers/helpers";
import AddIcon from "@material-ui/icons/Add";

const EditProductTypeForm = ({
  handleSubmit,
  handleChange,
  newFieldType,
  productFieldNames,
  editMode,
  setEditMode,
  setNewFieldType,
}) => {
  const filteredProductFieldNames = productFieldNames.filter((el) => {
    return el.fieldType !== 3 && el.fieldType !== 6;
  });
  return (
    <Paper>
      <form  onSubmit={handleSubmit} style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "1200px"}}>
        <div style={{padding: "20px", width: "400px"}}>
          <Input
            className="productType__type__input__field"
            type="text"
            value={newFieldType.fieldName}
            disabled={newFieldType.fieldName === "Name"}
            name="fieldName"
            onChange={handleChange}
            placeholder="Enter field name"
            required
          />
        </div>
        <div style={{padding: "20px", width: "300px"}}>
          <Select
            className="productType__type__input__field"
            native
            name="fieldType"
            value={newFieldType.fieldType}
            onChange={handleChange}
            disabled={editMode}
          >
            {filteredProductFieldNames
              .filter((el) => el.fieldType !== 7)
              .map(({ id, fieldType }) => (
                <option key={id} value={fieldType}>
                  {proccessFieldType(fieldType)}
                </option>
              ))}
          </Select>
        </div>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between"}}>
          <Checkbox
            style={{ paddingLeft: "20px"}}
            value={newFieldType.isRequired}
            name="isRequired"
            onChange={handleChange}
            color="primary"
            size="small"
            checked={newFieldType.isRequired}
          />
          <span>Required</span>
        </div>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between"}}>
          <Checkbox
            style={{ paddingLeft: "20px"}}
            value={newFieldType.searchable}
            name="searchable"
            onChange={handleChange}
            color="primary"
            size="small"
            checked={newFieldType.searchable}
          />
          <span>Searchable</span>
        </div>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between"}}>
          <Checkbox
            style={{ paddingLeft: "20px"}}
            value={newFieldType.unique}
            name="unique"
            onChange={handleChange}
            color="primary"
            size="small"
            checked={newFieldType.unique}
          />
          <span>Unique</span>
        </div>

        <Button
          startIcon={<AddIcon fontSize="small" />}
          style={{width: "300px"}}
          size="medium"
          color="primary"
          type="submit"
        >
          {editMode ? "Update Type Field" : "Add Type Field"}
        </Button>

        {editMode && (
          <Button
            size="medium"
            color="primary"
            onClick={() => {
              setEditMode(false);
              setNewFieldType({
                fieldName: "",
                fieldType: 1,
                isRequired: false,
                searchable: false,
                unique: false,
              });
            }}
          >
            Cancel
          </Button>
        )}
      </form>
    </Paper>
  );
};

export default EditProductTypeForm;
