import React from "react";
import Logo from "../../static/images/signin-logo.png";
import { UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { Button } from "@material-ui/core";

const SignIn = () => {
  const { instance } = useMsal();
  return (
    <div className="signin">
      <div className="signin__box">
        <div className="signin__image">
          <img src={Logo} alt="" />
        </div>
        <form action="">
          <div className="signin__button">
            <UnauthenticatedTemplate>
              <Button
                variant="contained"
                color="primary"
                onClick={(e) => (
                  instance.loginRedirect(loginRequest), e.preventDefault(e)
                )}
              >
                Sign In
              </Button>
            </UnauthenticatedTemplate>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignIn;
