import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import DetailsModal from "../modal/DetailsModal/DetailsModal";
import ProductTypeModal from "../modal/ProductTypeModal/ProductTypeModal";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";

const ProductTypeTable = ({ productTypesData, getProducts, loadingIndicator }) => {
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [deleteId, setDeleteId] = useState();

  if (!productTypesData.length && loadingIndicator) {
    return (
      <div className="loading_div">
        <div className="lds-dual-ring"></div>
      </div>
    );
  }
  if (!productTypesData.length && !loadingIndicator) {
    return (
      <div className="loading_div">
        <h1>No Results Found!</h1>
      </div>
    );
  }
  return (
    <>
      <DetailsModal open={isOpenDelete} onClose={() => setIsOpenDelete(false)}>
        <ProductTypeModal
          getProducts={getProducts}
          deleteId={deleteId}
          setIsOpenDelete={setIsOpenDelete}
          onClose={() => setIsOpenDelete(false)}
        />
      </DetailsModal>
      <TableContainer style={{ width: "1200px", padding: "40px"}}>
        <Table aria-label="a dense table">
          <TableHead>
            <TableRow className="table__head">
              <TableCell className="table__head__cell" align="left">
                Name
              </TableCell>
              <TableCell className="table__head__cell" align="center">
                Category
              </TableCell>
              <TableCell className="table__head__cell" align="center">
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {productTypesData &&
              productTypesData.map(({ id, productTypeName, categoryName }) => (
                <TableRow key={id} hover tabIndex={-1}>
                  <TableCell align="left">{productTypeName}</TableCell>
                  <TableCell align="center">{categoryName}</TableCell>

                  <TableCell align="right">
                    <Box style={{ display: "flex", justifyContent: "center"}}>
                      <Box ml={1}>
                        <Button
                          size="large"
                          component={Link}
                          to={`/productTypes/edit/${encodeURIComponent(
                            productTypeName
                          )}`}
                          startIcon={<EditOutlinedIcon className="edit-icon" />}
                        >
                        </Button>
                      </Box>
                      <Box ml={1}>
                        <Button
                          size="large"
                          color="primary"
                          startIcon={<DeleteOutlineIcon className="delete-icon" />}
                          onClick={() => {
                            setIsOpenDelete(true);
                            setDeleteId(id);
                          }}
                        >
                        </Button>
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ProductTypeTable;
