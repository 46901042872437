import axios from "axios";
import { PC_CAVE_URL } from "../../config/config";
import { TOAST_ALERT } from "../../static/toastify/toastify";

export const getItemByProductId = async (id) => {
  let response;
  try {
    response = await axios.get(`${PC_CAVE_URL}/items/${id}`, {
      headers: {
        // Authorization: `Bearer ${extractToken()}`,
      },
    });
  } catch (error) {
    console.log("asd")
    TOAST_ALERT("Error occured", "error");
    throw error;
  }
  return response;
};
