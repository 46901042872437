import React  from "react";
import { useMsal } from "@azure/msal-react";
import { GET_ORDERS, defaultStartingPage } from "../../../config/config";
import { loginRequest } from "../../../authConfig";
import { Button, Box, Paper } from "@material-ui/core/";
import { deleteOrderById } from "../../../api/ordersApi/ordersApi";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";



const DeleteOrderModal = ({
    deleteOrderId,
    setIsOpenDelete,
    paginationOrderChange,
}) => {

    const { id } = deleteOrderId;
    const { instance } = useMsal();


    const deleteOrder = async () => {
        await deleteOrderById(id)
            .then((res) => {
                paginationOrderChange(defaultStartingPage, GET_ORDERS);
                setIsOpenDelete(false);
            })
            .catch((err) => {
                const message = err.message.split(" ");
                if (message[message.length - 1] === "401") {
                    instance.logout(loginRequest);
                }
            });
    }

    return (
        <Paper>
            <div className="modal">
                <div className="modal__header">
                    <h2>Are you sure?</h2>
                </div>
                <div className="modal__btn__group">
                    <div className="modal__btn__1">
                        <Box ml={1}>
                            <Button
                                size="small"
                                color="primary"
                                startIcon={<CloseIcon fontSize="small" className="category-form-btn-color" />}
                                onClick={() => setIsOpenDelete(false)}
                            >
                                <span className="category-form-btn-color">Cancel</span>
                            </Button>
                        </Box>
                    </div>
                    <div className="modal__btn__2">
                        <Box ml={1}>
                            <Button
                                onClick={deleteOrder}
                                color="secondary"
                                size="small"
                                className="button__delete"
                                startIcon={<DeleteIcon fontSize="small" />}
                            >
                                Delete
                            </Button>
                        </Box>
                    </div>
                </div>
            </div>
        </Paper>
    );
};


export default DeleteOrderModal;
