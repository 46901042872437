import React, { useContext, useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { AuthContext } from "../../context/AuthProvider";
import { makeStyles } from "@material-ui/core/styles";
import DeleteOrderModal from "../modal/DeleteOrderModal/DeleteOrderModal";
import OrderNoteModal from "../modal/OrderNoteModal/OrderNoteModal";
import { Link } from "react-router-dom";
import Moment from 'moment';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Button,
} from "@material-ui/core";
import DetailsModal from "../modal/DetailsModal/DetailsModal";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { OrdersFormProvider } from "../../context/OrdersProvider";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    textAlign: "center",
    justifyContent: "space-around",
    color: theme.palette.text.secondary,
  },
}));

const OrdersTable = ({
  ordersData,
  columns,
  loadingIndicator,
  paginationOrderChange,
  setLoadingIndicator
}) => {
  const classes = useStyles();
  const { userRole } = useContext(AuthContext);
  const { handleCurrency, currency } = useContext(OrdersFormProvider);
  const [openPopup, setOpenPopup] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [deleteOrderId, setDeleteOrderId] = useState("");
  const [orderNote, setOrderNote] = useState("");
  const { instance } = useMsal();

  const orderStatusEnums = {
    inProgress: "InProgress",
    completed: "Completed"
  }

  const handleOpen = () => {
    setOpenPopup(true);
  };

  const handleClose = () => {
    setOpenPopup(false);
  }
  
  if (!ordersData.length && !loadingIndicator) {
    return (
      <div className="loading_div">
        <div className="lds-dual-ring"></div>
      </div>
    );
  }

  if (ordersData.length === 0) {
    return (
      <div className="loading_div" style={{ paddingTop: '50px' }}>
        <h1>No Results Found!</h1>
      </div>
    );
  }

  return (
    <>
      <DetailsModal open={isOpenDelete} onClose={() => setIsOpenDelete(false)}>
        <DeleteOrderModal
          deleteOrderId={deleteOrderId}
          paginationOrderChange={paginationOrderChange}
          setIsOpenDelete={setIsOpenDelete}
          onClose={() => setIsOpenDelete(false)}
        />
      </DetailsModal>
      <TableContainer className={classes.container}>
        <Table aria-label="a dense table" className="product-table-container">
          <TableHead>
            <TableRow className="table__head">
              {columns.map((column, i) => (
                <TableCell
                  key={column.id}
                  className="table__head__cell"
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {ordersData &&
              ordersData.map((order) => {
                return (
                  <TableRow hover tabIndex={-1} key={order.id}>

                    <TableCell>
                      {order.deliveryAddress}
                      <br />
                    </TableCell>

                    <TableCell className="capitalize" align="left">
                      {Moment(order.createdOrderDate).format('YYYY-MM-DD')}
                    </TableCell>

                    <TableCell className="capitalize" align="left">
                      {Moment(order.deliveryOrderDate).format('YYYY-MM-DD')}
                    </TableCell>

                    <TableCell className="capitalize" align="left">
                      {order.supplier}
                    </TableCell>

                    <TableCell className="status" align="center">
                      {order.status === 1 ? <b>{orderStatusEnums.inProgress}</b> : <b>{orderStatusEnums.completed}</b>}
                    </TableCell>

                    <TableCell className="capitalize" align="center">
                      <Button
                        id="create__product__button"
                        variant="text"
                        size="small"
                        onClick={() => {
                          handleOpen();
                          setOrderNote(order.note);
                        }}
                      >
                        <span className="add-new-product-span">Note Description</span>
                      </Button>
                      <OrderNoteModal
                        note={orderNote}
                        openPopup={openPopup}
                        handleClose={handleClose}
                      />

                    </TableCell>

                    <TableCell className="capitalize" align="center">
                      <Button
                        color="primary"
                        variant="text"
                        size="small"
                        component={Link}
                        to={`/orders/orderItems/${order.id}`}>
                        Order Items
                      </Button>

                    </TableCell>

                    <TableCell className="capitalize" align="left">
                      {handleCurrency(order.currency)}
                    </TableCell>


                    <TableCell align="center">
                      <Box display="flex" justifyContent="flex-end">
                        <Box ml={1}>
                          {userRole === "Admin" ? (
                            <Button
                              className="button__edit"
                              startIcon={<EditOutlinedIcon className="edit-icon" />}
                              component={Link}
                              to={`/orders/update/${order.id}`}
                            >
                            </Button>
                          ) : null}
                        </Box>
                        <Box ml={1}>
                          {userRole === "Admin" && order.status !== 2 ? (
                            <Button
                              onClick={() => {
                                console.log(order.status);
                                setIsOpenDelete(true);
                                setDeleteOrderId({
                                  id: order.id
                                });
                              }}
                              className="button__delete"
                              startIcon={<DeleteOutlineIcon className="delete-icon" />}
                            >
                            </Button>
                          )
                            : (<Button
                              onClick={() => {
                                setIsOpenDelete(true);
                                setDeleteOrderId({
                                  id: order.id
                                });
                              }}
                              disabled
                              
                              startIcon={<DeleteOutlineIcon />}
                            >
                            </Button>)}
                        </Box>
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
export default OrdersTable;
