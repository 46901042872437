import React from "react";
import { dropdownValues } from "../../../config/config";
import { InputLabel, MenuItem, FormControl, Select } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const DropdownComponent = ({ numProducts, numOrders, numProductTypes, dropdownProductTypeChange, dropdownChange, dropdownOrderChange, path }) => {
  const classes = useStyles();

  return (
    <div className="dropdown-component">
      {(() => {
        if (path === "Orders") {
          return (
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-simple-select-label">Records per page</InputLabel>
              <Select

                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={numOrders}
                onChange={(e, { props: { value } }) => dropdownOrderChange(value, path)}
              >
                {dropdownValues.map((value) => (

                  <MenuItem key={value} value={value}>
                    <span className="dropdown-component-item">{value}</span>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          );
        }

        else if(path === "Products"){
          return (
            <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">Records per page</InputLabel>
            <Select
    
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={numProducts}
              onChange={(e, { props: { value } }) => dropdownChange(value, path)}
            >
              {dropdownValues.map((value) => (
                <MenuItem key={value} value={value}>
                  <span className="dropdown-component-item">{value}</span>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          );
        }
        else if(path === "ProductTypes"){
          return(
            <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">Records per page</InputLabel>
            <Select
    
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={numProductTypes}
              onChange={(e, { props: { value } }) => dropdownProductTypeChange(value, path)}
            >
              {dropdownValues.map((value) => (
                <MenuItem key={value} value={value}>
                  <span className="dropdown-component-item">{value}</span>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          )
        }
      })()}

    </div>
  );
};

export default DropdownComponent;
