import axios from "axios";
import authAxios from "../authAxios/authAxios";
import { URL } from "../../config/config";
import { extractToken } from "../../helpers/helpers";
import { TOAST_ALERT } from "../../static/toastify/toastify";

export const getSearchDataFromBE = async (path, page, size, search, tags) => {
  let response;
  const mappedTags = tags
    ? tags.map((tag) => `&TagNames=${encodeURIComponent(tag)}`).join("")
    : " ";

  const searchTag1 = `&Value=${encodeURIComponent(search || " ")}`;
  const searchTag2 = `&UserName=${encodeURIComponent(search || " ")}`;

  try {
    response = await axios.get(
      `${URL}/${path}?PageNumber=${page}&PageSize=${size}${searchTag1}${mappedTags}${searchTag2}`,
      {
        headers: {
          Authorization: `Bearer ${extractToken()}`,
        },
      }
    );
  } catch (error) {
    console.log(error)
    console.log(error.message);
    throw error;
  }
  return response;
};

//Delete Product
export const deleteProductById = async (deleteId) => {
  let response;
  const { id } = deleteId;
  try {
    response = await axios.delete(`${URL}/Products/${id}`, {
      headers: {
        Authorization: `Bearer ${extractToken()}`,
      },
    });
    if (response.data === false) {
      TOAST_ALERT("Product is already borrowed", "error");
    } else {
      TOAST_ALERT("Product Deleted", "success");
    }
  } catch (error) {
    console.log("del")
    TOAST_ALERT("Error occured", "error");
    throw error;
  }
};

// Create Product
export const createProduct = async (newProduct) => {
  await axios
    .post(`${URL}/Products`, newProduct, {
      headers: {
        Authorization: `Bearer ${extractToken()}`,
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => {
      TOAST_ALERT("Product Created", "success");
    })
    .catch((err) => {
      TOAST_ALERT("Product with field value already exists", "error");
      throw err;
    });
};

// Update Product
export const processUpdateProduct = async (newProduct, id) => {
  await authAxios
    .put(`Products/${id}`, newProduct)
    .then((res) => {
      TOAST_ALERT("Product Edited", "success");
    })
    .catch((err) => {
      console.log("del")
      TOAST_ALERT("Error occured", "error");
      throw err;
    });
};

// Get single Product
export const getSingleProduct = async (id) => {
  let res;
  try {
    res = await axios.get(`${URL}/Products/${id}`, {
      headers: {
        Authorization: `Bearer ${extractToken()}`,
      },
    });
  } catch (err) {
    console.log("del")
    TOAST_ALERT("Error occured", "error");
    throw err;
  }
  return res;
};

// get all Products lenght for home dashboard (to filter)
export const getAllProductsList = async () => {
  let res;
  try {
    res = await axios(`${URL}/Products?PageNumber=1&PageSize=100`, {
      headers: {
        Authorization: `Bearer ${extractToken()}`,
      },
    });
  } catch (err) {
    console.log("del")
    // TOAST_ALERT("Error occured", "error");
    throw err;
  }

  return res;
};

export const updateProductFieldValue = async (value, productId) => {
  await axios
    .put(`${URL}/ProductFieldValues`, value, {
      headers: {
        Authorization: `Bearer ${extractToken()}`,
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => {
      TOAST_ALERT("Product Edited", "success");
      // console.log(productId);
    })
    .catch((err) => {
      TOAST_ALERT("Product Value not changed", "error");
      throw err;
    });
};

export const createProductFieldValue = async (value) => {
  await axios
    .post(`${URL}/ProductFieldValues`, value, {
      headers: {
        Authorization: `Bearer ${extractToken()}`,
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => {
      TOAST_ALERT("Product Edited", "success");
    })
    .catch((err) => {
      console.log("del")
      TOAST_ALERT("Error occured", "error");
      throw err;
    });
};

export const getAllProductNames = async () => {
  let res;
  res = await axios
    .get(`${URL}/Products/productNames`, {
      headers: {
        Authorization: `Bearer ${extractToken()}`
      },
    })
    // .then((res) => {
    //   TOAST_ALERT("Product Edited", "success");
    // })
    .catch((err) => {
      console.log("del")
      TOAST_ALERT("Error occured", "error");
      throw err;
    });
    return res;
};

