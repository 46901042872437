import React, { useEffect, useContext, useState } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../context/AuthProvider";
import { TagsContext } from "../../context/TagsContextProvider";
import {
  defaultNumProductsPerPage,
  defaultStartingPage,
  GET_PRODUCTS,
} from "../../config/config";
import SearchHOC from "../../HOC/SearchHOC";
import ProductsTable from "../../components/productsTable/ProductsTable";
import Dropdown from "../../components/searchComponents/dropdown/Dropdown";
import Pagination from "../../components/searchComponents/pagination/Pagination";
import Button from "@material-ui/core/Button";
import SearchComponent from "../../components/searchComponents/search/Search";

const ProductsPage = ({
  productsData,
  setProductsData,
  getProductsData,
  totalPages,
  setDeleteId,
  page,
  numProducts,
  search,
  multipleSearch,
  cancelSearch,
  setCancelSearch,
  loadingIndicator,
  multipleSearchChange,
  searchChange,
  paginationChange,
  setLoadingIndicator,
  dropdownChange,
  handleSubmit,
  handleCancel,
  homeTagSearch,
  setHomeTagSearch,
}) => {
  const [view, setView] = useState(false);
  const { userRole } = useContext(AuthContext);
  const { getTags } = useContext(TagsContext);

  useEffect(() => {
    getProductsData(
      GET_PRODUCTS,
      defaultStartingPage,
      defaultNumProductsPerPage,
      " ",
      // homeTagSearch || []
    );
    // setTagsFromHome(homeTagSearch, multipleSearchChange);
    // getTags();
    return () => {
      setProductsData([]);
      // setHomeTagSearch([]);
    };
  }, []);

  // const setTagsFromHome = (homeTagSearch, multipleSearchChange) => {
  //   if (homeTagSearch.length) {
  //     multipleSearchChange(homeTagSearch);
  //     setCancelSearch(false);
  //   }
  // };

  //TABLE COLUMNS
  const columns = [
    { id: "name", label: "Name", minWidth: 200 },
    {
      id: "productType",
      label: "Product type",
      align: "left",
    },
    {
      id: "actions",
      label: "Actions",
      align: "right",
    },
  ];

  return (
    <div className="product-page">
      <div className="product-page-dropdown-create">
        <div>
          <h2>Products</h2>
          <span>Currently {productsData.length} products added</span>
        </div>
        <div className="product-page-search">
          <SearchComponent
            searchChange={searchChange}
            search={search}
            multipleSearchChange={multipleSearchChange}
            multipleSearch={multipleSearch}
            handleSubmit={handleSubmit}
            handleCancel={handleCancel}
            cancelSearch={cancelSearch}
            setCancelSearch={setCancelSearch}
            labelSearch=""
            path={GET_PRODUCTS}
            homeTagSearch={homeTagSearch}
          />
        </div>
        <div className="product-page-add-btn">
          {userRole === "Admin" ? (
            <Button
              id="create__product__button"
              variant="text"
              size="large"
              component={Link}
              to="/products/create"
            >
              <span className="add-new-product-span">+Add new product</span>
            </Button>
          ) : null}
        </div>
      </div>
      <ProductsTable
        paginationChange={paginationChange}
        columns={columns}
        productsData={productsData}
        loadingIndicator={loadingIndicator}
        setDeleteId={setDeleteId}
        path={GET_PRODUCTS}
        viewModel={view}
        setLoadingIndicator={setLoadingIndicator}
      />

      <div className="product-page-results-paggination">
        <Dropdown
          numProducts={numProducts}
          dropdownChange={dropdownChange}
          path={GET_PRODUCTS}
        />
        <Pagination
          page={page}
          paginationChange={paginationChange}
          totalPages={totalPages}
          path={GET_PRODUCTS}
        />
      </div>
    </div>
  );
};

export default SearchHOC(ProductsPage);
