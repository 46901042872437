import React, { createContext, useState } from "react";
import { getTagsData } from "../api/tagsApi/tagsApi";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";

export const TagsContext = createContext();

export const TagsContextProvider = (props) => {
  const [tags, setTags] = useState([]);
  const { instance } = useMsal();

  const getTags = async () => {
    await getTagsData()
      .then(({ data }) => {
        setTags(data.length && data.slice(0, 20));
      })
      .catch((err) => {
        const message = err.message.split(" ");
        if (message[message.length - 1] === "401") {
          instance.logout(loginRequest);
        }
      });
  };

  const values = {
    tags,
    setTags,
    getTags,
  };

  return (
    <TagsContext.Provider value={values}>{props.children}</TagsContext.Provider>
  );
};

export default TagsContextProvider;
