import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { ModalContext } from "../../context/ModalContextProvider";
import { AuthContext } from "../../context/AuthProvider";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Toolbar, IconButton } from "@material-ui/core";
import { useMsal } from "@azure/msal-react";
import MenuIcon from "@material-ui/icons/Menu";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    padding: 0,
  },
  toolbar: {
    padding: 0,
  },
}));

const Header = () => {
  const classes = useStyles();
  const { userRole } = useContext(AuthContext);
  const { instance } = useMsal();

  const { responsiveHeaderStatus, setResponsiveHeaderStatus } = useContext(
    ModalContext
  );

  return (
    <div className={classes.root}>
      <AppBar position="static" id="toolbar-product">
        <Toolbar className={classes.toolbar}>
          <div className="toolbar__inner">
            <span className="pc-header">Polar<span className="pc-header-cave">Product</span></span>
            <div className="header__links">
              <div className="headerlinks__desktop">
                <div>

                  <Link className="header__link" to="/categories">
                    Categories
                  </Link>

                  {userRole === "Admin" && (
                    <Link className="header__link" to="/productTypes">
                      Product Types
                    </Link>
                  )}

                  <Link className="header__link" to="/products">
                    Products
                  </Link>

                  <Link className="header__link" to="/orders">
                    Orders
                  </Link>


                </div>
              </div>
              <div className="headerlinks__mobile">
                <IconButton
                  edge="start"
                  className={classes.menuButton}
                  color="inherit"
                  aria-label="menu"
                  onClick={() =>
                    setResponsiveHeaderStatus(!responsiveHeaderStatus)
                  }
                >
                  <MenuIcon />
                </IconButton>
              </div>
            </div>
            <div>
            <IconButton onClick={() => instance.logout()}>
              <FontAwesomeIcon size="2x" icon={faSignOutAlt} style={{ color: "lightblue" }} />
            </IconButton>
          </div>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default Header;
