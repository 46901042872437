import React, { useContext } from "react";
import ReactDOM from "react-dom";
import { useMsal } from "@azure/msal-react";
import { Link } from "react-router-dom";
import { ModalContext } from "../../context/ModalContextProvider";
import { IconButton, List, ListItem } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { AuthContext } from "../../context/AuthProvider";
import Brightness4Icon from "@material-ui/icons/Brightness4";
import Brightness5Icon from "@material-ui/icons/Brightness5";

const ResponsiveHeader = ({ mode, setMode }) => {
  const { responsiveHeaderStatus, setResponsiveHeaderStatus } = useContext(
    ModalContext
  );
  const { instance } = useMsal();
  const { userRole } = useContext(AuthContext);
  if (!responsiveHeaderStatus) return null;

  return ReactDOM.createPortal(
    <>
      <div className="responsiveheader__overlay">
        <div className="responsiveheader__btn">
          <FontAwesomeIcon
            onClick={() => setResponsiveHeaderStatus(false)}
            icon={faTimesCircle}
          />
        </div>
        <List component="nav" aria-label="secondary mailbox folders">
          <ListItem button>
            <Link to="/">Home</Link>
          </ListItem>
          <ListItem button>
            <Link to="/products">Products</Link>
          </ListItem>
          <ListItem button>
            <Link to="/history">Rent History</Link>
          </ListItem>
          <ListItem button>
            {userRole === "Admin" && <Link to="/productTypes">Product Types</Link>}
          </ListItem>
          <ListItem>
            {mode === "dark" ? (
              <IconButton
                onClick={() => setMode("light")}
                style={{ color: "#fff" }}
              >
                <Brightness5Icon />
              </IconButton>
            ) : (
              <IconButton
                onClick={() => setMode("dark")}
                style={{ color: "#fff" }}
              >
                <Brightness4Icon />
              </IconButton>
            )}
          </ListItem>
          <ListItem button>
            <IconButton onClick={() => instance.logout()}>
              <FontAwesomeIcon icon={faSignOutAlt} style={{ color: "white" }} />
            </IconButton>
          </ListItem>
        </List>
      </div>
    </>,
    document.getElementById("responsive-header")
  );
};

export default ResponsiveHeader;
