import {
    Button,
    Typography,
    Grid,
    InputLabel,
    Card,
    CardContent,
    Input,
    Select,
    MenuItem
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { getAllProductNames } from "../../api/productsApi/productsApi";
import { getSingleOrder, updateOrderItem } from "../../api/ordersApi/ordersApi";


const UpdateOrderItemForm = (props) => {


    const { history } = props;
    const { orderId, orderItemId } = props.match.params;
    const [selected, setSelected] = useState('');
    const [orderItemForUpdate, setOrderItemForUpdate] = useState({});
    const [productNames, setProductNames] = useState([]);
    const [isDisabled, setIsDisabled] = useState(false);

    orderItemForUpdate.orderId = orderId;
    const handleUpdateOrderItem = async (event) => {
        event.preventDefault();
        try {
            await updateOrderItem(orderItemForUpdate);
            history.push(`/orders/orderItems/${orderId}`);
        }
        catch (error) {
            console.log(error);
        }
    }

    const handleProductNames = async () => {
        await getAllProductNames().then((res) => {
            setProductNames(res.data);
        });
    }


    const getOrderForUpdate = async () => {
        await getSingleOrder(orderId).then((res) => {
            const orderItemForUpdate = res.data.orderItems.find(orderItem => orderItem.id === orderItemId);
            setOrderItemForUpdate(orderItemForUpdate);
            setSelected(orderItemForUpdate.productName);
        });
    }
    useEffect(() => {
        handleProductNames();
    }, []);

    useEffect(() => {
        getOrderForUpdate();
    }, []);


    const handleChange = (event) => {
        const { name, value } = event.target;
        setOrderItemForUpdate({ ...orderItemForUpdate, [name]: value });
        if (name === "productName") {
            setSelected(value);
        }
    };

    const handleClick = () => {
        setIsDisabled(true);
    };

    return (
        <Grid container>
            <Card style={{ maxWidth: 600, margin: '0 auto', padding: '20px 20px' }}>
                <CardContent>
                    <Typography gutterBottom variant="h2" style={{ textAlign: 'center' }}>
                        Update Order Item
                    </Typography>
                    <form>
                        <Grid container>

                            <Grid xs={12} item style={{ paddingTop: '20px', paddingRight: '20px' }}>
                                <InputLabel required htmlFor="bootstrap-input" style={{ paddingBottom: '5px' }}>
                                    Product Name
                                </InputLabel>
                                <Select
                                    value={selected}
                                    required
                                    fullWidth
                                    variant="standard"
                                    name="productName"
                                    onChange={handleChange}
                                >
                                    {productNames.map((productName, index) => {
                                        return (
                                            <MenuItem key={index} value={productName}>{productName}</MenuItem>
                                        )
                                    })}

                                </Select>
                            </Grid>

                            <Grid xs={12} sm={6} item style={{ paddingTop: '50px', paddingRight: "20px" }}>
                                <InputLabel required htmlFor="bootstrap-input" style={{ paddingBottom: '25px' }}>
                                    Price
                                </InputLabel>
                                <Input
                                    variant="standard"
                                    type="number"
                                    value={orderItemForUpdate.price}
                                    required
                                    fullWidth
                                    name="price"
                                    onChange={handleChange}
                                />
                            </Grid>

                            <Grid xs={12} sm={6} item style={{ paddingTop: '50px' }}>
                                <InputLabel required htmlFor="bootstrap-input" style={{ paddingBottom: '25px' }}>
                                    Quantity
                                </InputLabel>
                                <Input
                                    variant="standard"
                                    type="number"
                                    required
                                    value={orderItemForUpdate.quantity}
                                    fullWidth
                                    name="quantity"
                                    onChange={handleChange}
                                />
                            </Grid>

                            <Grid xs={12} sm={6} item style={{ paddingTop: '80px' }}>
                                <Button
                                    type="submit"
                                    varient="outlined"
                                    color="primary"
                                    disabled={isDisabled}
                                    fullWidth
                                    onClick={(e) => {
                                        handleClick();
                                        handleUpdateOrderItem(e);
                                    }}
                                >
                                    Submit
                                </Button>
                            </Grid>
                            <Grid xs={12} sm={6} item style={{ paddingTop: '80px' }}>
                                <Button
                                    type="button"
                                    varient="outlined"
                                    color="primary"
                                    fullWidth
                                    onClick={() => history.push(`/orders/orderItems/${orderId}`)}
                                >
                                    GO BACK
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </CardContent>
            </Card>
        </Grid>
    )
}

export default UpdateOrderItemForm;
