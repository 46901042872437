import { useState, useEffect } from "react";
import {
    Button,
    Grid,
    InputLabel,
    Input,
    Select,
    MenuItem
} from "@material-ui/core";
import { getAllProductNames } from "../../api/productsApi/productsApi";
import { TOAST_ALERT } from "../../static/toastify/toastify";

const OrderListDynamicTable = ({ setOrderItems }) => {

    const [orderItemsList, setOrderItemsList] = useState([{ quantity: '', price: '', productName: '' }]);
    const [productNames, setProductNames] = useState([]);
    const [selected, setSelected] = useState('');

    const handleAddOrderItem = () => {
        setOrderItemsList([...orderItemsList, { quantity: '', price: '', productName: '' }]);
    }

    const handleProductNames = async () => {
        await getAllProductNames().then((res) => {
            setProductNames(res.data);
        });
    }

    useEffect(() => {
        handleProductNames();
    }, []);

    const hangleOrderItemRemove = (index) => {
        const list = [...orderItemsList];
        list.splice(index, 1);
        setOrderItemsList(list);
    }

    const handleOrderItemChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...orderItemsList];
        list[index][name] = value;
        setOrderItemsList(list);

        setOrderItems(orderItemsList);
        if (name === "productName") {
            setSelected(value);
        }
    }

    return (
        <Grid>
            {orderItemsList.map((singleOrderItem, index) => {
                return (
                    <Grid xs={12} item container key={index}>
                        <Grid xs={12} sm={3} item style={{ paddingTop: '20px', paddingRight: '20px' }}>
                            <InputLabel required htmlFor="bootstrap-input" style={{ paddingBottom: '5px' }}>
                                Product Name
                            </InputLabel>
                            <Select
                                required
                                fullWidth
                                value={singleOrderItem.productName}
                                variant="standard"
                                name="productName"
                                onChange={(e) => {
                                    handleOrderItemChange(e, index)
                                }}
                            >
                                {productNames.map((productName, index) => {
                                    return (
                                        <MenuItem key={index} value={productName}>{productName}</MenuItem>
                                    )
                                })}

                            </Select>
                        </Grid>

                        <Grid xs={12} sm={3} item style={{ paddingTop: '20px', paddingRight: '20px' }}>
                            <InputLabel required htmlFor="bootstrap-input" style={{ paddingBottom: '5px' }}>
                                Quantity
                            </InputLabel>
                            <Input
                                variant="standard"
                                type="number"
                                required
                                fullWidth
                                name="quantity"
                                value={singleOrderItem.quantity}
                                onChange={(e) => handleOrderItemChange(e, index)}
                            />
                        </Grid>

                        <Grid xs={12} sm={3} item style={{ paddingTop: '20px', paddingRight: '20px' }}>
                            <InputLabel required htmlFor="bootstrap-input" style={{ paddingBottom: '5px' }}>
                                Price
                            </InputLabel>
                            <Input
                                variant="standard"
                                type="number"
                                required
                                fullWidth
                                name="price"
                                value={singleOrderItem.price}
                                onChange={(e) => handleOrderItemChange(e, index)}
                            />
                        </Grid>

                        <Grid xs={12} sm={3} item style={{ paddingTop: '35px', paddingLeft: '30px' }}>
                            {orderItemsList.length > 1 &&
                                <Button
                                    color="secondary"
                                    variant="outlined"
                                    onClick={() => hangleOrderItemRemove(index)}>
                                    Remove
                                </Button>}
                        </Grid>
                    </Grid>
                )
            })}
            <Grid xs={12} sm={3} item style={{ paddingTop: '20px' }}>
                {orderItemsList.length <= 7 &&
                    <Button
                        type="submit"
                        varient="contained"
                        color="primary"
                        fullWidth
                        onClick={handleAddOrderItem}
                    >
                        Add Order Item
                    </Button>}
            </Grid>
        </Grid>

    )
};

export default OrderListDynamicTable;