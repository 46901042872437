// Imports
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-4-1!../node_modules/postcss-loader/src/index.js??postcss!./pages/CreateProductType/CreateProductType.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-4-1!../node_modules/postcss-loader/src/index.js??postcss!./components/header/Header.css";
import ___CSS_LOADER_AT_RULE_IMPORT_2___ from "-!../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-4-1!../node_modules/postcss-loader/src/index.js??postcss!./components/signIn/SignIn.css";
import ___CSS_LOADER_AT_RULE_IMPORT_3___ from "-!../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-4-1!../node_modules/postcss-loader/src/index.js??postcss!./components/productsTable/ProductsTable.css";
import ___CSS_LOADER_AT_RULE_IMPORT_4___ from "-!../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-4-1!../node_modules/postcss-loader/src/index.js??postcss!./components/searchComponents/search/Search.css";
import ___CSS_LOADER_AT_RULE_IMPORT_5___ from "-!../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-4-1!../node_modules/postcss-loader/src/index.js??postcss!./components/modal/DeleteModalDetails/DeleteModal.css";
import ___CSS_LOADER_AT_RULE_IMPORT_6___ from "-!../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-4-1!../node_modules/postcss-loader/src/index.js??postcss!./components/form/Form.css";
import ___CSS_LOADER_AT_RULE_IMPORT_7___ from "-!../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-4-1!../node_modules/postcss-loader/src/index.js??postcss!./components/responsiveHeader/ResponsiveHeader.css";
import ___CSS_LOADER_AT_RULE_IMPORT_8___ from "-!../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-4-1!../node_modules/postcss-loader/src/index.js??postcss!./static/css/layout.css";
import ___CSS_LOADER_AT_RULE_IMPORT_9___ from "-!../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-4-1!../node_modules/postcss-loader/src/index.js??postcss!./pages/ProductsPage/ProductsPage.css";
import ___CSS_LOADER_AT_RULE_IMPORT_10___ from "-!../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-4-1!../node_modules/postcss-loader/src/index.js??postcss!./static/css/Loading.css";
import ___CSS_LOADER_AT_RULE_IMPORT_11___ from "-!../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-4-1!../node_modules/postcss-loader/src/index.js??postcss!./components/productDetails/ProductsDetails.css";
import ___CSS_LOADER_AT_RULE_IMPORT_12___ from "-!../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-4-1!../node_modules/postcss-loader/src/index.js??postcss!./components/searchComponents/pagination/Pagination.css";
import ___CSS_LOADER_AT_RULE_IMPORT_13___ from "-!../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-4-1!../node_modules/postcss-loader/src/index.js??postcss!./components/editProductForm/EditProductForm.css";
import ___CSS_LOADER_AT_RULE_IMPORT_14___ from "-!../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-4-1!../node_modules/postcss-loader/src/index.js??postcss!./components/categoryTable/categoryTable.css";
import ___CSS_LOADER_AT_RULE_IMPORT_15___ from "-!../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-4-1!../node_modules/postcss-loader/src/index.js??postcss!./components/categoryForm/CategoryForm.css";
import ___CSS_LOADER_AT_RULE_IMPORT_16___ from "-!../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-4-1!../node_modules/postcss-loader/src/index.js??postcss!./static/css/querys.css";
import ___CSS_LOADER_AT_RULE_IMPORT_17___ from "-!../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-4-1!../node_modules/postcss-loader/src/index.js??postcss!./components/searchComponents/search/SingleSearch.css";
import ___CSS_LOADER_AT_RULE_IMPORT_18___ from "-!../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-4-1!../node_modules/postcss-loader/src/index.js??postcss!./components/searchComponents/dropdown/Dropdown.css";
import ___CSS_LOADER_AT_RULE_IMPORT_19___ from "-!../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-4-1!../node_modules/postcss-loader/src/index.js??postcss!./components/createProductForm/CreateProductForm.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_3___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_4___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_5___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_6___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_7___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_8___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_9___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_10___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_11___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_12___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_13___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_14___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_15___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_16___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_17___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_18___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_19___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "* {\n  padding: 0;\n  margin: 0;\n  box-sizing: border-box;\n  font-family: \"Montserrat\", \"Open Sans\";\n}\n", "",{"version":3,"sources":["webpack://src/App.css"],"names":[],"mappings":"AAsBA;EACE,UAAU;EACV,SAAS;EACT,sBAAsB;EACtB,sCAAsC;AACxC","sourcesContent":["@import \"./pages/CreateProductType/CreateProductType.css\";\n@import \"./components/header/Header.css\";\n@import \"./components/signIn/SignIn.css\";\n@import \"./components/productsTable/ProductsTable.css\";\n@import \"./components/searchComponents/search/Search.css\";\n@import \"./components/modal/DeleteModalDetails/DeleteModal.css\";\n@import \"./components/form/Form.css\";\n@import \"./components/responsiveHeader/ResponsiveHeader.css\";\n@import \"./static/css/layout.css\";\n@import \"./pages/ProductsPage/ProductsPage.css\";\n@import \"./static/css/Loading.css\";\n@import \"./components/productDetails/ProductsDetails.css\";\n@import \"./components/searchComponents/pagination/Pagination.css\";\n@import \"./components/editProductForm/EditProductForm.css\";\n@import \"./components/categoryTable/categoryTable.css\";\n@import \"./components/categoryForm/CategoryForm.css\";\n@import \"./static/css/querys.css\";\n@import \"./components/searchComponents/search/SingleSearch.css\";\n@import \"./components/searchComponents/dropdown/Dropdown.css\";\n@import \"./components/createProductForm/CreateProductForm.css\";\n\n\n* {\n  padding: 0;\n  margin: 0;\n  box-sizing: border-box;\n  font-family: \"Montserrat\", \"Open Sans\";\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
