import React from "react";
import { deleteProductType } from "../../../api/productTypeApi/productTypeApi";
import { Button, Box, Paper } from "@material-ui/core/";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";

const ProductTypeModal = ({ deleteId, setIsOpenDelete, getProducts }) => {
  const handleDelete = () => {
    const deleteProduct = async () => {
      await deleteProductType(deleteId);
      await getProducts();
      setIsOpenDelete(false);
    };
    deleteProduct();
  };

  return (
    <Paper>
      <div className="modal">
        <div className="modal__header">
          <h2>Are you sure?</h2>
        </div>
        <div className="modal__btn__group">
          <div className="modal__btn__1">
            <Box ml={1}>
              <Button
                size="small"
                color="primary"
                startIcon={<CloseIcon fontSize="small" />}
                onClick={() => setIsOpenDelete(false)}
              >
                Cancel
              </Button>
            </Box>
          </div>
          <div className="modal__btn__2">
            <Box ml={1}>
              <Button
                onClick={handleDelete}
                color="secondary"
                size="small"
                startIcon={<DeleteIcon fontSize="small" />}
              >
                Delete
              </Button>
            </Box>
          </div>
        </div>
      </div>
    </Paper>
  );
};

export default ProductTypeModal;
