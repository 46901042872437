import React, { useState, createContext } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { defaultStartingPage } from "../config/config";
import { getSearchDataFromBE } from "../api/productsApi/productsApi";
import { getOrders } from "../api/ordersApi/ordersApi";
import { getAllProductTypes } from "../api/productTypeApi/productTypeApi";

export const SearchContext = createContext();

const SearchContextProvider = (props) => {
  const [productsData, setProductsData] = useState([]);
  const [ordersData, setOrdersData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [productTypesData, setProductTypesData] = useState([]);
  //SEARCHES THAT WORK WITH PAGINATION
  const [search, setSearch] = useState("");
  const [searchQuery, setSearchQuery] = useState({});
  const [searchProductTypeQuery, setSearchProductTypeQuery] = useState({});
  const [multipleSearch, setMultipleSearch] = useState([]);
  const { instance } = useMsal();
  const [homeTagSearch, setHomeTagSearch] = useState([]);

  const getProductTypesData = async (
    path,
    page,
    numProductTypes
  ) => {

    await getAllProductTypes(
      path,
      page,
      numProductTypes,
      searchQuery
    )
      .then(({ data, headers }) => {
        const { TotalCount, TotalPages } = JSON.parse(headers["x-pagination"]);

        setProductTypesData(data);
        setTotalCount(TotalCount);
        setTotalPages(TotalPages);
      })
      .catch((err) => {
        const message = err.message.split(" ");
        if (message[message.length - 1] === "401") {
          instance.logout(loginRequest);
        }
      });
  };

  const getAllProductTypesData = async (
    path,
    page,
    numProductTypes,
    searchQuery
  ) => {

    await getAllProductTypes(
      path,
      page,
      numProductTypes,
      searchQuery
    )
      .then(({ data, headers }) => {
        const { TotalCount, TotalPages } = JSON.parse(headers["x-pagination"]);

        setProductTypesData(data);
        setTotalCount(TotalCount);
        setTotalPages(TotalPages);
      })
      .catch((err) => {
        const message = err.message.split(" ");
        if (message[message.length - 1] === "401") {
          instance.logout(loginRequest);
        }
      });
  };

  const getOrdersData = async (
    path,
    page,
    numOrders,
    searchQuery
  ) => {

    await getOrders(
      path,
      page,
      numOrders,
      searchQuery
    )
      .then(({ data, headers }) => {
        const { TotalCount, TotalPages } = JSON.parse(headers["x-pagination"]);

        setOrdersData(data);
        setTotalCount(TotalCount);
        setTotalPages(TotalPages);
      })
      .catch((err) => {
        const message = err.message.split(" ");
        if (message[message.length - 1] === "401") {
          instance.logout(loginRequest);
        }
      });
  };

  const cancelSearchProductTypesData = async (
    path,
    defaultStartingPage,
    numOrders,
    searchQuery
  ) => {

    await getAllProductTypes(
      path,
      defaultStartingPage,
      numOrders,
      searchQuery
    )
      .then(({ data, headers }) => {
        const { TotalCount, TotalPages } = JSON.parse(headers["x-pagination"]);

        setProductTypesData(data);
        setTotalCount(TotalCount);
        setTotalPages(TotalPages);
      })
      .catch((err) => {
        const message = err.message.split(" ");
        if (message[message.length - 1] === "401") {
          instance.logout(loginRequest);
        }
      });
  };


  const cancelSearchOrderData = async (
    path,
    defaultStartingPage,
    numOrders,
    searchQuery
  ) => {

    await getOrders(
      path,
      defaultStartingPage,
      numOrders,
      searchQuery
    )
      .then(({ data, headers }) => {
        const { TotalCount, TotalPages } = JSON.parse(headers["x-pagination"]);

        setOrdersData(data);
        setTotalCount(TotalCount);
        setTotalPages(TotalPages);
      })
      .catch((err) => {
        const message = err.message.split(" ");
        if (message[message.length - 1] === "401") {
          instance.logout(loginRequest);
        }
      });
  };



  //WORKS WITH SEARCH,PAGINATION AND DROPDOWN
  const getProductsData = async (
    path,
    page,
    numProducts,
    searchTerm = search,
    multipleSearchTerm = multipleSearch
  ) => {
    setSearch(searchTerm);
    setMultipleSearch(multipleSearchTerm);


    await getSearchDataFromBE(
      path,
      page,
      numProducts,
      searchTerm || search,
      multipleSearchTerm || multipleSearch
    )
      .then(({ data, headers }) => {
        const { TotalCount, TotalPages } = JSON.parse(headers["x-pagination"]);

        setProductsData(data);
        setTotalCount(TotalCount);
        setTotalPages(TotalPages);
      })
      .catch((err) => {
        const message = err.message.split(" ");
        if (message[message.length - 1] === "401") {
          instance.logout(loginRequest);
        }
      });
  };

  //CANCEL DATA AND GET DEFAULT DATA
  const cancelSearchData = async (path, numProducts) => {
    //CLEAR SEARCHES AND GET DEFAULT DATA
    setSearch("");
    setMultipleSearch([]);

    await getSearchDataFromBE(path, defaultStartingPage, numProducts)
      .then(({ config, data, headers }) => {
        const { TotalCount, TotalPages } = JSON.parse(headers["x-pagination"]);

        setProductsData(data);
        setTotalCount(TotalCount);
        setTotalPages(TotalPages);
      })
      .catch((err) => {
        const message = err.message.split(" ");
        if (message[message.length - 1] === "401") {
          instance.logout(loginRequest);
        }
      });
  };

  const values = {
    productsData,
    ordersData,
    setProductsData,
    productTypesData,
    getAllProductTypes,
    setProductTypesData,
    getProductTypesData,
    setOrdersData,
    cancelSearchOrderData,
    cancelSearchProductTypesData,
    searchProductTypeQuery,
    setSearchProductTypeQuery,
    getAllProductTypesData,
    searchQuery,
    setSearchQuery,
    totalPages,
    totalCount,
    getProductsData,
    getOrdersData,
    cancelSearchData,
    homeTagSearch,
    setHomeTagSearch,
  };

  return (
    <SearchContext.Provider value={values}>
      {props.children}
    </SearchContext.Provider>
  );
};

export default SearchContextProvider;
